import React from "react";
import Page from "../../components/global/Page";

const Terms = () => {
  return (
    <Page title="Terminos y Condiciones">
      <p>
        T&Eacute;RMINOS Y CONDICIONES QUE REGULAN EL USO Y MANTENIMIENTO DE LAS
        INSTALACIONES PROPIEDAD DE Legion Train Center S.A de C.V. O CONOCIDO
        COMO &ldquo;Legion High Performance Training&rdquo;, SU NOMBRE
        COMERCIAL, MISMO QUE SER&Aacute; APLICABLE PARA TODOS LOS USUARIOS Y
        PERSONAS QUE SE ENCUENTREN EN LAS INSTALACIONES.
      </p>

      <p>
        CAP&Iacute;TULO I.- DISPOSICIONES GENERALES PRIMERO.- Este documento de
        T&eacute;rminos, Condiciones y Pol&iacute;ticas establece las bases para
        el desarrollo de las actividades dentro de las instalaciones de
        &ldquo;Legion High Performance Training&rdquo;, las relaciones entre los
        Usuarios y el Personal, y entre Usuarios, rige todas sus instalaciones,
        tanto las que actualmente tiene establecidas en AV FUNDADORES 103, COL.
        LOMAS DE MONTECRISTO, C.P. 64909,MONTERREY, NUEVO LEON., como en
        cualesquiera otras instalaciones que llegare a establecer, sean
        permanentes o temporales.
      </p>

      <p>
        &nbsp;SEGUNDO.- Para efectos del presente documento, as&iacute; como sus
        respectivos Anexos, deben entenderse los conceptos y abreviaturas como
        sigue:
      </p>

      <p>
        AVISO DE PRIVACIDAD: Documento oficial publicado por &ldquo;Legion High
        Performance Training&rdquo; en el cual se hace responsable por la
        recopilaci&oacute;n de datos que hace de los Usuarios, y el cual se
        encuentra a su disposici&oacute;n. Anexo I.
      </p>

      <p>
        CONTRATO: Significa el acuerdo de voluntades entre la Empresa y cada uno
        de sus Usuarios, en los cuales se pactan los t&eacute;rminos y
        condiciones de uso.
      </p>

      <p>
        CLASE: Se define como el periodo de tiempo en el cual el Usuario se
        encuentra frente al Instructor y se desarrolla la actividad
        f&iacute;sica.
      </p>

      <p>
        CONDICIONES DEL USUARIO: Enti&eacute;ndase los t&eacute;rminos y
        condiciones establecidas en el presente documento, los cuales se exponen
        a los Usuarios para que sean sabedores de los mismos.
      </p>

      <p>
        &nbsp;DATOS PERSONALES: Significa toda aquella informaci&oacute;n que se
        relaciona con los Usuarios y los identifica dentro de la Empresa.
      </p>

      <p>
        DERECHOS ARCO: Derechos que tiene el Usuario de acceso,
        rectificaci&oacute;n, cancelaci&oacute;n y oposici&oacute;n al
        tratamiento de sus datos personales.
      </p>

      <p>
        EMPRESA: Significa indistintamente &ldquo;Legion High Performance
        Training&rdquo;
      </p>

      <p>
        HORARIO: Enti&eacute;ndase como el periodo de tiempo durante el cual se
        pueden desarrollar actividades en las instalaciones de &ldquo;Legion
        High Performance Training&rdquo;.
      </p>

      <p>
        INSTALACIONES: Se definen como el local en el cual se llevan a cabo las
        actividades de &ldquo;Legion High Performance Training&rdquo;&nbsp;
        establecidas en AV FUNDADORES 103, COL. LOMAS DE MONTECRISTO, C.P.
        64909, MONTERREY, NUEVO LEON
      </p>

      <p>
        INSTRUCTOR: Persona integrante del personal de &ldquo;Legion High
        Performance Training&rdquo;&nbsp; encargada de impartir la clase.
      </p>

      <p>
        MEDIOS DE CONTACTO: V&iacute;as de comunicaci&oacute;n que tiene el
        Usuario para con la empresa, sea virtual en el sitio web: MyLegion.mx,
        correo electr&oacute;nico: info@mylegion.mx, Redes Sociales creadas por
        &ldquo;Legion High Performance Training&rdquo;, presencial en las
        Instalaciones de la Empresa y/o n&uacute;meros telef&oacute;nicos que
        establezca para tales efectos.
      </p>

      <p>
        MEMBRES&Iacute;A: Significa condici&oacute;n de miembro de &ldquo;Legion
        High Performance Training&rdquo;.
      </p>

      <p>
        PAGO: Cantidad o monto de dinero que se realiza a favor de &ldquo;Legion
        High Performance Training&rdquo;&nbsp; por parte del usuario por medio
        de transferencia electr&oacute;nica y/o cualquier otro m&eacute;todo de
        pago como contraprestaci&oacute;n a un bien o servicio que ser&aacute;
        recibido.
      </p>

      <p>
        PERSONAL: Cualquier persona que preste sus servicios a favor de la
        Empresa &ldquo;Legion High Performance Training&rdquo; y se encuentre
        identificada plenamente por parte de la Empresa &ldquo;Legion High
        Performance Training&rdquo;.
      </p>

      <p>
        POL&Iacute;TICAS: Enti&eacute;ndase como el conjunto de principios que
        permiten orientar el comportamiento y/o actuar de los Usuarios dentro de
        las Instalaciones de la Empresa.
      </p>

      <p>
        REGLAMENTO: El Reglamento de uso y mantenimiento de las Instalaciones.
      </p>

      <p>
        SERVICIO: Conjunto de actividades, clases, asesor&iacute;as que
        &ldquo;Legion High Performance Training&rdquo;&nbsp; pone a
        disposici&oacute;n de los Usuarios para su contrataci&oacute;n.
      </p>

      <p>
        SITIO WEB: Significa el espacio virtual de internet creado por
        &ldquo;Legion High Performance Training&rdquo;&nbsp; con el dominio
        MyLegion.mx
      </p>

      <p>
        &ldquo;LEGION HIGH PERFORMANCE TRAINING&rdquo;: Nombre comercial
        registrado de la persona moral&nbsp; denominada como Legion Train Center
        S.A de C.V., constituida legalmente bajo las leyes mexicanas.
      </p>

      <p>
        USUARIO: Quien(es) utilizan las instalaciones de &ldquo;Legion High
        Performance Training&rdquo; , asisten a Clase, o se encuentran dentro de
        la Empresa.
      </p>

      <p>
        VALORES: Se entender&aacute; por valores los bienes y/o pertenencias de
        los Usuarios que se ingresen a las Instalaciones de &ldquo;Legion High
        Performance Training&rdquo;.
      </p>

      <p>
        VIGENCIA: Periodo de tiempo durante el cual el Contrato, reglamento o
        documento legal emitido por &ldquo;Legion High Performance
        Training&rdquo;&nbsp; es v&aacute;lido para todos los efectos legales
        que hayan a lugar.
      </p>

      <p>
        TERCERO.- El presente documento ser&aacute; dado a conocer a todos los
        Usuarios y ser&aacute; p&uacute;blico as&iacute; como sus documentos
        anexos, estando disponible en el Sitio Web: MyLegion.mx, e instalaciones
        de la Empresa, siendo su observancia obligatoria para los Usuarios de
        los Servicios proporcionados por la Empresa.
      </p>

      <p>CAP&Iacute;TULO II.- DE LOS SERVICIOS</p>

      <p>
        CUARTO.- &ldquo;Legion High Performance Training&rdquo;&nbsp; ofrece a
        los Usuarios servicios que consisten en la realizaci&oacute;n de
        ejercicio de alto rendimiento, en espec&iacute;fico ejercicios
        f&iacute;sicos mediante el uso de bicicletas fijas, conocido como
        Spinning en las clases y ejercicios basados en intervalos que conllevan
        fuerza y resistencia, en un horario y local establecido de &ldquo;Legion
        High Performance Training&rdquo;, as&iacute; como servicios relacionados
        a dichas clases, incluyendo de asesor&iacute;a en materia de
        acondicionamiento f&iacute;sico.
      </p>

      <p>
        QUINTO.- Para poder utilizar los Servicios, el usuario deber&aacute; de
        ingresar al Sitio Web MyLegion.mx y solicitar en el mismo el alta de su
        cuenta de usuario personal, siguiendo las instrucciones y proporcionando
        la informaci&oacute;n en el propio Sitio Web requerida.
      </p>

      <p>
        SEXTO.- El Usuario deber&aacute; aceptar los presentes T&eacute;rminos y
        Condiciones mediante la opci&oacute;n electr&oacute;nica de
        aceptaci&oacute;n de las presentes Condiciones del Usuario que aparece
        en el Sitio Web, y dicha aceptaci&oacute;n se entender&aacute; como el
        consentimiento expreso entre el Usuario y &ldquo;Legion High Performance
        Training&rdquo; para celebrar el presente contrato y cumplir las
        obligaciones en el mismo establecidas (&ldquo;Contrato&rdquo;).
      </p>

      <p>
        S&Eacute;PTIMO. Al momento en que el Usuario acepte las presentes
        Condiciones del Usuario, &ldquo;Legion High Performance Training&rdquo;
        autorizar&aacute; el alta de su cuenta de usuario personal, por lo que
        se obliga y acepta entregar a &ldquo;Legion High Performance
        Training&rdquo; la informaci&oacute;n personal que considere en esta
        fecha o en fecha posterior necesaria o conveniente para poder brindar
        los Servicios.
      </p>

      <p>
        OCTAVO.- Los datos personales obtenidos ser&aacute;n tratados conforme
        al Aviso de Privacidad de &ldquo;Legion High Performance
        Training&rdquo;, se&ntilde;alado como Anexo I, y en cumplimiento a lo
        dispuesto por la Ley Federal de Protecci&oacute;n de Datos Personales en
        Posesi&oacute;n de los Particulares.
      </p>

      <p>
        NOVENO.- El Usuario acepta proporcionar los datos de su tarjeta de
        cr&eacute;dito u otro medio de pago electr&oacute;nico que &ldquo;Legion
        High Performance Training&rdquo; acepte como medio de pago por los
        Servicios y manifiesta su aprobaci&oacute;n para que &ldquo;Legion High
        Performance Training&rdquo; realice los cargos que correspondan por los
        Servicios contratados.
      </p>

      <p>
        D&Eacute;CIMO.- Tras cumplimentar adecuadamente el registro con
        &ldquo;Legion High Performance Training&rdquo;, se le
        proporcionar&aacute; al Usuario una cuenta personal con la cual
        podr&aacute; acceder a trav&eacute;s del Sitio Web MyLegion.mx, con el
        nombre de usuario y la contrase&ntilde;a que se elija. El usuario
        ser&aacute; el &uacute;nico responsable del manejo de su
        contrase&ntilde;a y de mantenerla confidencial.
      </p>

      <p>
        D&Eacute;CIMO PRIMERO.- El Usuario acepta y reconoce que las
        autorizaciones, contrataciones de Servicios y consentimientos que se
        realicen utilizando su nombre de usuario y contrase&ntilde;a se
        entender&aacute;n hechas por el en lo personal y como consentimiento
        expreso de su parte. Por lo anterior se recomienda no revelar a persona
        alg&uacute;n nombre de usuario y/o contrase&ntilde;a. &ldquo;Legion High
        Performance Training&rdquo; no ser&aacute; responsable por el uso
        inadecuado, o los cargos que se realicen en su tarjeta de cr&eacute;dito
        u otro medio de pago que haya proporcionado a trav&eacute;s del uso de
        nombre de usuario y contrase&ntilde;a.
      </p>

      <p>CAP&Iacute;TULO III.- DEL PROCESO DE INSCRIPCI&Oacute;N</p>

      <p>
        D&Eacute;CIMO SEGUNDO.- Al Usuario se le permitir&aacute; apartar un
        lugar dentro de las Clases de &ldquo;Legion High Performance
        Training&rdquo;&nbsp; que se encuentren disponibles en el sistema de
        acuerdo a la disponibilidad de capacidad de la misma. Al momento en que
        indique qu&eacute; clase desee tomar, el Usuario estar&aacute; en la
        lista de asistentes a dicha Clase.
      </p>

      <p>
        D&Eacute;CIMO TERCERO.- En el momento en que indique y solicite se
        aparte un cupo dentro de dicha clase, el Usuario estar&aacute;
        confirmando su asistencia a la misma y por lo tanto, autoriza a
        &ldquo;Legion High Performance Training&rdquo; para que realice el cargo
        autom&aacute;tico a la tarjeta de cr&eacute;dito o medio de pago
        electr&oacute;nico que usted proporcion&oacute; en la
        p&aacute;ginaMyLegion.mx al momento de crear su cuenta de usuario, por
        concepto de la clase a la que usted asista, de acuerdo al costo del
        paquete seleccionado por el Usuario.
      </p>

      <p>CAP&Iacute;TULO IV.- USO DEL SITIO WEB O EL SERVICIO</p>

      <p>
        D&Eacute;CIMO CUARTO.- El Usuario deber&aacute; garantizar que la
        informaci&oacute;n proporcionada a &ldquo;Legion High Performance
        Training&rdquo; es completa y veraz, ya que la Empresa tendr&aacute;
        derecho, en todo momento, a comprobar la veracidad de la
        informaci&oacute;n facilitada.
      </p>

      <p>
        D&Eacute;CIMO QUINTO.- Es responsabilidad del Usuario asegurarse de
        ingresar al Sitio Web con dominio &uacute;nico
        &ldquo;www.MyLegion.mx&rdquo;, ya que la Empresa no ser&aacute;
        responsable si no ingresa al Sitio Web correcto. &ldquo;Legion High
        Performance Training&rdquo; se reserva el derecho a limitar, restringir
        o incluso prohibir su ingreso al sistema del Sitio Web con su nombre de
        usuario y contrase&ntilde;a al Sitio Web, asimismo se reserva el derecho
        de limitar, restringir o prohibir en cualquier momento que el Usuario
        utilice sus Servicios, por cualquier causa y sin necesidad de
        justificarlo.
      </p>

      <p>
        D&Eacute;CIMO SEXTO.- Al utilizar el Sitio Web o los Servicios, el
        Usuario acuerda y consiente que:
      </p>

      <p>
        I. S&oacute;lo utilizar&aacute; los Servicios y el Sitio Web
        exclusivamente de manera personal, por lo que no podr&aacute; ceder sus
        espacios en clases u otros Servicios en favor de terceros;
      </p>

      <p>II. No autorizar&aacute; a otros a usar su cuenta;</p>

      <p>
        III. No ceder&aacute; ni transferir&aacute; de otro modo su cuenta o
        derechos bajo este contrato a ninguna otra persona o entidad legal;
      </p>

      <p>
        IV. No utilizar&aacute; una cuenta que est&eacute; sujeta a cualquier
        derecho de una persona que no sea si mismo sin la autorizaci&oacute;n
        adecuada;
      </p>

      <p>
        V. No utilizar&aacute; los Servicios o el Sitio Web con fines
        il&iacute;citos, incluyendo, sin limitaci&oacute;n, para enviar o
        almacenar ning&uacute;n material ilegal o con fines fraudulentos;
      </p>

      <p>
        VI. No utilizar&aacute; los Servicios o el Sitio Web para causar
        molestias, trastornos o inconvenientes;
      </p>

      <p>
        &nbsp;VII. No perjudicar&aacute; el funcionamiento adecuado de la red;
      </p>

      <p>
        VIII. No tratar&aacute; de da&ntilde;ar los Servicios o el Sitio Web de
        ning&uacute;n modo;
      </p>

      <p>
        IX. No copiar&aacute; ni distribuir&aacute; ning&uacute;n contenido o
        Servicios de &ldquo;Legion High Performance Training&rdquo; sin el
        permiso escrito de &ldquo;Legion High Performance Training&rdquo; ;
      </p>

      <p>
        X. Guardar&aacute; de forma segura y confidencial la contrase&ntilde;a
        de su cuenta y cualquier identificaci&oacute;n facilitada para
        permitirle acceder al Servicio y al Sitio Web;
      </p>

      <p>
        XI. Facilitar&aacute; toda las pruebas de identidad que se le soliciten
        razonablemente de tiempo en tiempo;
      </p>

      <p>
        XII. Cumplir&aacute; con toda la legislaci&oacute;n y normas aplicables
        al usar el Sitio Web o los Servicios.
      </p>

      <p>
        XIII. Mantendr&aacute; una buena conducta y ser&aacute; respetuoso con
        las dem&aacute;s personas que utilicen los Servicios;
      </p>

      <p>
        XIV. Se obliga a respetar y cumplir los reglamentos que &ldquo;Legion
        High Performance Training&rdquo; emita en relaci&oacute;n con los
        Servicios. &ldquo;Legion High Performance Training&rdquo; se reserva el
        derecho a terminar en cualquier momento y de manera inmediata sin
        necesidad de declaraci&oacute;n judicial los Servicios objeto de las
        presentes Condiciones del Usuario, en caso de que el Usuario incumpla
        con cualquiera de las normas anteriores.
      </p>

      <p>
        &nbsp;D&Eacute;CIMO S&Eacute;PTIMO.- La informaci&oacute;n,
        recomendaciones u otros servicios prestados en o a trav&eacute;s del
        Sitio Web y o por el uso de los Servicios, son s&oacute;lo
        informaci&oacute;n general y no constituyen un aviso. &ldquo;Legion High
        Performance Training&rdquo; se ocupar&aacute; de mantener el Sitio Web y
        sus contenidos de forma razonablemente correcta y actualizada, pero no
        podr&aacute; garantizar que los contenidos del Sitio Web carezcan de
        errores, defectos, malware y virus y que el Sitio Web sea correcto,
        est&eacute; actualizado y sea preciso. As&iacute; mismo no ser&aacute;
        responsable por ning&uacute;n da&ntilde;o derivado del uso del (o
        incapacidad de usar) del Sitio Web, incluyendo los da&ntilde;os causados
        por malware, virus o cualquier informaci&oacute;n incorrecta o
        incompleta de la informaci&oacute;n del Sitio Web, salvo que este
        da&ntilde;o se derive de cualquier conducta dolosa o negligencia grave
        por parte de &ldquo;Legion High Performance Training&rdquo;, Por
        &uacute;ltimo, la Empresa no ser&aacute; responsable por los
        da&ntilde;os derivados del uso de (o incapacidad de usar) los medios de
        comunicaci&oacute;n electr&oacute;nicos con el Sitio Web, incluyendo sin
        limitaci&oacute;n da&ntilde;os derivados del fallo o retraso en la
        entrega de comunicaciones electr&oacute;nicas, intercepci&oacute;n o
        manipulaci&oacute;n de comunicaciones electr&oacute;nicas por terceros o
        por programas inform&aacute;ticos usados para comunicaciones
        electr&oacute;nicas y transmisi&oacute;n de virus.
      </p>

      <p>CAP&Iacute;TULO V.- PAGOS E INDEMINIZACIONES</p>

      <p>
        D&Eacute;CIMO OCTAVO.- El uso del Sitio Web ser&aacute; gratuito.
        Podr&aacute; encontrar informaci&oacute;n sobre las tarifas aplicables
        para los Servicios en el Sitio Web. &ldquo;Legion High Performance
        Training&rdquo; podr&aacute; modificar o actualizar las mismas
        ocasionalmente, sin necesidad de previo aviso.
      </p>

      <p>
        D&Eacute;CIMO NOVENO.- Ser&aacute; responsabilidad del Usuario
        mantenerse informado sobre las tarifas actuales para los Servicios
        &ldquo;Legion High Performance Training&rdquo;. El Usuario acuerda pagar
        todos los Servicios que solicite (con independencia de si los utiliza o
        asiste a las clases), mediante cargo autom&aacute;tico a tarjeta de
        cr&eacute;dito o medio de pago electr&oacute;nico que haya proporcionado
        a trav&eacute;s del Sitio Web. El costo de los Servicios causa Impuesto
        al Valor Agregado. En todo caso, ser&aacute; responsable del pago
        puntual de todos los Servicios que solicite.
      </p>

      <p>
        &nbsp;VIG&Eacute;SIMO.- Los pagos y cargos realizados no son
        reembolsables. &ldquo;Legion High Performance Training&rdquo;
        podr&aacute; utilizar procesadores de pagos de terceros para vincular la
        tarjeta de cr&eacute;dito o el medio de pago electr&oacute;nico que
        proporcione el Usuario a su nombre y contrase&ntilde;a en el Sitio Web.
        El procesamiento de pagos, con respecto al uso que haga de los Servicios
        estar&aacute; sujeto a las condiciones y pol&iacute;ticas de privacidad
        de Stripe o cualquier m&eacute;todo de pago contratado por Legion Train
        Center S.A de C.V. y el emisor de su tarjeta de cr&eacute;dito
        adem&aacute;s de a estas Condiciones del Usuario. &ldquo;Legion High
        Performance Training&rdquo; no ser&aacute; responsable de ning&uacute;n
        error del Procesador de Pago. En relaci&oacute;n con el uso del Sitio
        Web y los Servicios, &ldquo;Legion High Performance Training&rdquo;
        obtendr&aacute; determinados datos de la transacci&oacute;n, que
        utilizar&aacute; &uacute;nicamente de conformidad al aviso de privacidad
        de &ldquo;Legion High Performance Training&rdquo;. Todas las
        transacciones con tarjeta ser&aacute;n procesadas a trav&eacute;s de
        Stripe o cualquier m&eacute;todo de pago contratado por Legion Train
        Center S.A de C.V.
      </p>

      <p>
        VIG&Eacute;SIMO PRIMERO.- Al aceptar estos t&eacute;rminos y condiciones
        y utilizar su nombre de usuario y contrase&ntilde;a en el Sitio Web, el
        Usuario acuerda y acepta que libera a &ldquo;Legion High Performance
        Training&rdquo; de toda y cualquier responsabilidad, y se obliga a
        indemnizar y mantener indemne a la Empresa, sus filiales, licenciatarios
        y todos sus directivos, directores, instructores, otros usuarios,
        trabajadores, representantes y asesores por cualesquiera reclamaciones,
        costos, da&ntilde;os, p&eacute;rdidas, responsabilidades y gastos
        (incluyendo honorarios y gastos de abogados) derivados de o en
        relaci&oacute;n con: I. Violaci&oacute;n o incumplimiento de cualquier
        condici&oacute;n de estas Condiciones del Usuario o cualquier ley o
        reglamento aplicable, se haga o no referencia al mismo en el presente;
        II. Violaci&oacute;n de cualquier derecho de cualquier tercero, o III.
        Uso o uso incorrecto del Sitio Web o los Servicios.
      </p>

      <p>CAP&Iacute;TULO VI.- DEL CONTRATO DE SERVICIOS</p>

      <p>
        VIG&Eacute;SIMO SEGUNDO.- El contrato que se suscribe entre
        &ldquo;Legion High Performance Training&rdquo; y el Usuario ser&aacute;
        por un periodo indefinido, el cual tendr&aacute;n derecho a finalizar el
        Contrato en todo momento, siempre y cuando el Usuario mediante escrito
        libre solicite a la Empresa deshabilite su nombre de usuario del Sitio
        Web.
      </p>

      <p>
        VIG&Eacute;SIMO TERCERO.- &ldquo;Legion High Performance Training&rdquo;
        tendr&aacute; derecho a terminar el Contrato en todo momento y con
        efecto inmediato (deshabilitando el uso del Servicio) si el Usuario: I.
        Viola o incumple cualquier condici&oacute;n de las presentes Condiciones
        del Usuario, o II. A consideraci&oacute;n de &ldquo;Legion High
        Performance Training&rdquo;, hace un uso indebido del Sitio Web o los
        Servicios. &ldquo;Legion High Performance Training&rdquo; no
        estar&aacute; obligado a dar un aviso previo de la terminaci&oacute;n
        del Contrato. Despu&eacute;s de su terminaci&oacute;n &ldquo;Legion High
        Performance Training&rdquo; avisar&aacute; de ello con arreglo a las
        presentes Condiciones del Usuario.
      </p>

      <p>
        &nbsp;VIG&Eacute;SIMO CUARTO.- &ldquo;Legion High Performance
        Training&rdquo; se reserva el derecho, a su discreci&oacute;n, de
        modificar o sustituir cualquiera de los presentes t&eacute;rminos y
        condiciones del usuario, o cambiar, suspender o interrumpir los
        Servicios o el acceso del Sitio Web (incluyendo, sin limitaci&oacute;n,
        la disponibilidad de cualquier caracter&iacute;stica, base de datos o
        contenido) en cualquier momento mediante la publicaci&oacute;n de un
        aviso en el Sitio Web o a trav&eacute;s de correo electr&oacute;nico.
      </p>

      <p>
        VIG&Eacute;SIMO QUINTO.- &ldquo;Legion High Performance Training&rdquo;
        podr&aacute; emitir notificaciones o avisos al Usuario a trav&eacute;s
        de un aviso general en el Sitio Web o por correo electr&oacute;nico a la
        direcci&oacute;n registrada en la informaci&oacute;n de la cuenta de
        &ldquo;Legion High Performance Training&rdquo;, o mediante una
        comunicaci&oacute;n escrita enviada por correo ordinario a la
        direcci&oacute;n registrada en la informaci&oacute;n de la cuenta de la
        Empresa.
      </p>

      <p>
        VIG&Eacute;SIMO SEXTO.- Las partes est&aacute;n de acuerdo en que el
        Contrato se regir&aacute; por las leyes aplicables vigentes en
        Monterrey, Nuevo Le&oacute;n. Para la interpretaci&oacute;n y
        cumplimiento del Contrato, las partes se someten a la
        jurisdicci&oacute;n de los tribunales competentes en Monterrey, Nuevo
        Le&oacute;n, renunciando expresamente a cualquier otro fuero que pudiera
        corresponderles por raz&oacute;n de sus domicilios presentes o futuros o
        por cualquier otra causa.
      </p>

      <p>CAP&Iacute;TULO VII.- DE LOS VALORES Y BIENES PERSONALES</p>

      <p>
        VIG&Eacute;SIMO S&Eacute;PTIMO.- &ldquo;Legion High Performance
        Training&rdquo; no ser&aacute; responsable por la p&eacute;rdida, robo,
        o da&ntilde;os a cualquier objeto, incluyendo art&iacute;culos dejados
        en casilleros, ba&ntilde;os, estudios, o cualquier otro lugar en las
        instalaciones esto incluye el estacionamiento. Asimismo, el Usuario
        acepta y reconoce que &ldquo;Legion High Performance Training&rdquo;se
        reserva el derecho a denegar el acceso a cualquier persona que
        &ldquo;Legion High Performance Training&rdquo; considere que est&eacute;
        actuando de manera inadecuada o que pongan en riesgo su salud o la salud
        de los clientes.
      </p>

      <p>
        <br />
        VIGESIMO OCTAVO.- Cl&aacute;usula de Comprensi&oacute;n y
        Aceptaci&oacute;n de Riesgos: Los usuarios reconocen y aceptan
        voluntariamente los riesgos inherentes asociados con la
        participaci&oacute;n en actividades de spinning, ejercicios funcionales
        y gimnasio. Comprenden que estas actividades pueden resultar en
        lesiones, accidentes u otras condiciones adversas.
      </p>

      <p>
        <br />
        VIG&Eacute;SIMO NOVENO.- Cl&aacute;usula de Liberaci&oacute;n de
        Responsabilidad: En consideraci&oacute;n por permitir a los usuarios
        utilizar las instalaciones y participar en las actividades mencionadas,
        renuncian y liberan a &ldquo;Legion High Performance Training&rdquo; y
        su personal de cualquier responsabilidad por lesiones, da&ntilde;os o
        p&eacute;rdidas sufridas durante su participaci&oacute;n.
      </p>

      <p>
        TRIG&Eacute;SIMO.- Cl&aacute;usula de Responsabilidad Personal: Los
        usuarios son responsables de su propia seguridad y bienestar durante su
        tiempo en las instalaciones. Deben utilizar el equipo de manera
        adecuada, seguir las instrucciones del personal y abstenerse de
        participar en actividades que est&eacute;n m&aacute;s all&aacute; de sus
        capacidades.
      </p>

      <p>
        TRIG&Eacute;SIMO PRIMERO.- Cl&aacute;usula de Aprobaci&oacute;n
        M&eacute;dica: Los usuarios declaran que est&aacute;n en buena salud y
        f&iacute;sicamente aptos para participar en las actividades ofrecidas
        por &ldquo;Legion High Performance Training&rdquo; . Si tienen alguna
        condici&oacute;n m&eacute;dica preexistente que pueda verse afectada por
        el ejercicio, se comprometen a buscar el consejo de un profesional de la
        salud antes de participar.
      </p>

      <p>TRIG&Eacute;SIMO SEGUNDO.-Cl&aacute;usula de Menores de Edad:</p>

      <p>
        &nbsp;&nbsp; Los padres o tutores legales de los usuarios menores de
        edad reconocen y aceptan los riesgos asociados con la
        participaci&oacute;n de sus hijos en las actividades. Asumen la
        responsabilidad total de la supervisi&oacute;n y el bienestar de los
        menores en todo momento.
      </p>

      <p>
        TRIG&Eacute;SIMO TERCERO.- Cl&aacute;usula de Uso de Instalaciones y
        Equipo:
      </p>

      <p>
        &nbsp;&nbsp; Los usuarios se comprometen a utilizar las instalaciones y
        el equipo proporcionados por &ldquo;Legion High Performance
        Training&rdquo; de manera adecuada y segura. Cualquier da&ntilde;o
        causado debido a un uso indebido ser&aacute; responsabilidad del
        usuario.
      </p>

      <p>
        <br />
        TRIG&Eacute;SIMO CUARTO.- Cl&aacute;usula de Indemnizaci&oacute;n:
      </p>

      <p>
        &nbsp;&nbsp; Los usuarios acuerdan indemnizar y eximir de
        responsabilidad a &ldquo;Legion High Performance Training&rdquo; y su
        personal por cualquier reclamo, demanda, lesi&oacute;n, da&ntilde;o o
        p&eacute;rdida relacionados con su participaci&oacute;n en las
        actividades.
      </p>

      <p>
        <br />
        TRIG&Eacute;SIMO QUINTO.- Los t&eacute;rminos y condiciones descritos en
        el presente (&ldquo;Condiciones del Usuario&rdquo;) ser&aacute;n
        aplicables para el uso del sitio web y, en particular, para utilizar y
        recibir los Servicios.
      </p>

      <p>
        <br />
        &nbsp;Acepto t&eacute;rminos y condiciones.
      </p>
    </Page>
  );
};

export default Terms;
