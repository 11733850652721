import api from "./api";

const route = "/instructors";

const InstructorsService = {
  getInstructors: () => api.get(route),
  getHomeInstructors: () => api.get(`${route}/home/all`),
  getSingleInstructor: (instructor_id) => api.get(`${route}/${instructor_id}`),
  postInstructor: (instructor) => api.post(route, { ...instructor }),
  putInstructor: (instructor) => api.put(route, { ...instructor }),
  deleteInstructor: (instructor_id) => api.delete(`${route}/${instructor_id}`),
};

export default InstructorsService;
