import React, { useContext } from "react";
//import { S3_ENDPOINT } from "../../utils";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";

const UserData = ({ user, handleEdit }) => {
  const { name, last_name, birthdate, instagram, phone, email } = user;

  const { verifyEmail } = useContext(AuthContext);

  /*const getSrc = () => {
    let src = "icono-blanco.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };*/

  const renderBirthdate = () => {
    const birthday = moment(birthdate);
    if (birthday.isValid()) {
      return birthday.format("DD MMM YYYY");
    }
    return "No has agregado tu compleaños";
  };

  const renderVerification = () => {
    if (user.emailVerified) {
      return (
        <span>
          <i className="fa fa-check text-success me-2"></i> Verificado
        </span>
      );
    }
    return (
      <button onClick={verifyEmail} className="btn btn-sm btn-link mx-2">
        Verificar
      </button>
    );
  };

  return (
    <div className="container-fluid px-0">
      <h4 className="border-bottom pb-2">
        {name} {last_name}
      </h4>
      <div className="small mb-1">
        <span className="me-2">
          <i className="fa fa-envelope me-3"></i>
          {email}
        </span>
        {renderVerification()}
      </div>
      <div className="small mb-1">
        <i className="fa fa-birthday-cake me-3"></i>
        {renderBirthdate()}
      </div>
      <div className="small mb-1">
        <i className="fa fa-phone me-3"></i>
        {phone}
      </div>
      <div className="small mb-1">
        <i className="fab fa-instagram me-3"></i>
        {instagram}
      </div>
      <div className="row mt-4">
        <button
          onClick={handleEdit}
          className="btn mx-1 w-100 small hover-light py-1 px-2 text-left text-white"
        >
          <i className="fa fa-edit me-3"></i>Editar Informacion
        </button>
      </div>
    </div>
  );
};

export default UserData;
