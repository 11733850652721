
import {
  SET_MILESTONE,
  CREATE_MILESTONE,
  MILESTONES_RECEIVED,
  SET_PROPERTY_MILESTONE,
} from "../types/milestones";

const schema = {

}

const milestonesReducer = (state, { type, payload }) => {
  switch (type) {
    case MILESTONES_RECEIVED:
      return { ...state, milestones: payload };
    case SET_MILESTONE:
      return { ...state, milestone: payload };
    case CREATE_MILESTONE:
      return { ...state, milestone: schema };
    case SET_PROPERTY_MILESTONE: {
      const { key, value } = payload;
      const milestone = { ...state.milestone };
      milestone[key] = value;
      return { ...state, milestone };
    }
    default:
      return { ...state};
  }
};

export default milestonesReducer;
