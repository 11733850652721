import React from "react";
import parse from "html-react-parser";
import { S3_ENDPOINT } from "../../utils";

const InstructorRow = ({ instructor }) => {
  const renderImage = () => {
    const { file } = instructor;
    if (file && file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="mw-100 w-100 d-block m-auto"
        />
      );
    }
  };

  const renderBio = () => {
    if (String(instructor.short_bio).startsWith("<")) {
      return parse(instructor.short_bio);
    }
    return instructor.short_bio;
  };

  return (
    <div className="row align-items-center mb-4">
      <div className="col-12 col-md-4">{renderImage()}</div>
      <div className="col-12 col-md-8 position-relative">
        <h2 className="text-uppercase">COACH {instructor.nick_name}</h2>
        <div
          style={{
            top: 20,
            left: -110,
            width: 100,
            position: "absolute",
            borderBottom: "1px solid #fff",
          }}
          className="hide-mobile"
        ></div>
        <p>{renderBio()}</p>
        <h4>
          <i className="fa fa-music me-3 text-primary"></i>
          {instructor.music_type}
        </h4>
      </div>
    </div>
  );
};

export default InstructorRow;
