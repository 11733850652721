import React, { useContext, useEffect } from "react";
import MiInformacion from "../../views/MiInformacion";
import { AuthContext } from "../../context/AuthContext";
import AccountMenu from "./AccountMenu";
import LegalMenu from "./LegalMenu";
import AccountMilestone from "./AccountMilestones";

const AccountDashboard = () => {
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className="container px-0 pb-5">
      <MiInformacion />
      <AccountMilestone />
      <AccountMenu />
      <LegalMenu />
      <div className="card py-3 px-2 bg-gray border my-3">
        {" "}
        <button
          className="btn w-100 small hover-light py-1 px-2 text-left text-danger"
          onClick={signOut}
        >
          <i className="fa fa-sign-out-alt fa-flip-horizontal me-3"></i>Cerrar
          Sesion
        </button>
      </div>
    </div>
  );
};

export default AccountDashboard;
