import {
  RESERVATIONS_RECIBIDAS,
  DISABLE_RESERVATIONS,
  ENABLE_RESERVATIONS,
  SET_RESERVATION,
  SET_UPDATED,
} from "../types";

const ReservationsReducer = (state, { type, payload }) => {
  switch (type) {
    case RESERVATIONS_RECIBIDAS:
      return { ...state, reservations: payload, updated: false };
    case SET_UPDATED:
      return { ...state, updated: true };
    case DISABLE_RESERVATIONS:
      return { ...state, disabled: true };
    case ENABLE_RESERVATIONS:
      return { ...state, disabled: false };
    case SET_RESERVATION:
      return { ...state, reservation: payload };
    default:
      return { ...state };
  }
};

export default ReservationsReducer;
