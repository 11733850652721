import React, { useContext, useState, useEffect } from "react";
import ChooseLogin from "../components/auth/ChooseLogin";
import LoginForm from "../components/auth/LoginForm";
import { AuthContext } from "../context/AuthContext";
import { Link, navigate } from "@reach/router";

const Login = () => {
  const [selected, setSelected] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null) {
      navigate("/mylegion");
    }
  }, [user]);

  const renderStep = () => {
    switch (selected) {
      case "email":
        return <LoginForm />;
      default:
        return <ChooseLogin setLogin={setSelected} />;
    }
  };

  return (
    <div className="container-fluid bg-black">
      <div className="row">
        <div className="col-12 col-md-6 bg-vertical vh-100 px-0 hide-mobile"></div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid" id="login-card">
              <h1 className="text-center my-4 text-white">Entra ahora</h1>
              <div className="card no-scale text-left shadow p-4">
                {renderStep()}
              </div>
              <div className="container-fluid px-0 mt-4">
                ¿Aun no tienes cuenta?{" "}
                <Link to="/registro" className="text-primary">
                  Crea tu cuenta
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
