
import api from './api';

const route = '/AppConfig';

const AppConfigService = {
  getAppConfig: () => api.get(route),
};

export default AppConfigService
