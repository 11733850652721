import React from "react";
import ScheduleClass from "./ScheduleClass";
import moment from "moment";

const ScheduleDay = ({ day, type, clases, location }) => {
  const renderSingleClasses = () => {
    console.log(clases);
    if (Array.isArray(clases)) {
      if (location && location !== null && location !== "") {
        console.log(location);
        clases = clases.filter(
          (clase) => parseInt(clase.location_id) === parseInt(location)
        );
      }
      if (type && type !== null && type !== "") {
        clases = clases.filter(
          ({ class_type_id }) => parseInt(class_type_id) === parseInt(type)
        );
      }
      if (clases.length === 0) {
        return (
          <p className="show-mobile">No hay mas clases programadas este dia.</p>
        );
      }
      clases = clases.sort((a, b) => (a.class_date > b.class_date ? 1 : -1));
      return clases.map((clase) => (
        <ScheduleClass key={clase.single_class_id} singleClass={clase} />
      ));
    }
  };

  const renderDay = () => {
    const day_string = moment(day.date).utc().format("dd");
    switch (day_string) {
      case "Tu":
        return "MARTES";
      case "We":
        return "MIÉRCOLES";
      case "Th":
        return "JUEVES";
      case "Fr":
        return "VIERNES";
      case "Sa":
        return "SÁBADO";
      case "Su":
        return "DOMINGO";
      default:
        return "LUNES";
    }
  };

  return (
    <div className="schedule-col px-0 text-center">
      <div>
        <div className="row mx-0 pt-2">
          <div className="container-fluid text-center text-white">
            {moment(day.date).utc().format("DD")}
          </div>
        </div>
        <div className="row mx-0 my-3">
          <div className="container-fluid text-center bg-primary bold">
            {renderDay()}
          </div>
        </div>
      </div>
      <div className="px-2">{renderSingleClasses()}</div>
    </div>
  );
};

export default ScheduleDay;
