
import {
  APPCONFIG_RECEIVED,
} from "../types/AppConfig";

const schema = {
}

const AppConfigReducer = (state, { type, payload }) => {
  switch (type) {
    case APPCONFIG_RECEIVED:
      const objectParams = [
        'firebaseConfig', 
      ];
      let config = {};

      if (Array.isArray(payload)) {
        let params = payload;

        params.forEach(({ key, value }) => {
          if (objectParams.includes(key)) {
            value = JSON.parse(value);
          }
          config[key] = value;
        });

        if (!config.S3_ENDPOINT) {
          let S3_ENDPOINT = `https://${config.bucket}.s3.${config.region}.amazonaws.com`;
          config.S3_ENDPOINT = S3_ENDPOINT;
        }

      }
      return { ...state, ...config };
    default:
      return { ...state};
  }
};

export default AppConfigReducer;
