import React, { useContext, useEffect } from "react";
import Footer from "../components/global/Footer";
import { CheckoutContext } from "../context/CheckoutContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutDisclaimer from "../components/checkout/CheckoutDisclaimer";
import CheckoutClassPackage from "../components/checkout/CheckoutClassPackage";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";

const Checkout = ({ class_package_id }) => {
  //Checkout
  const {
    descuento,
    createOrder,
    setDescuento,
    discountCode,
    setPaymentSource,
    payment_source_id,
  } = useContext(CheckoutContext);

  useEffect(() => {
    return () => {
      setDescuento(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    let discount =
      descuento && descuento !== null ? descuento.code : discountCode;
    createOrder(class_package_id, payment_source_id, discount);
  };

  return (
    <div className="container-fluid px-0 text-white">
      <CheckoutHeader />
      <div className="container checkout px-3">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-4 my-2">
            <CheckoutClassPackage class_package_id={class_package_id} />
            <CheckoutDiscount class_package_id={class_package_id} />
          </div>
          <div className="col-12 col-md-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              paymentMethod={payment_source_id}
              class_package_id={class_package_id}
              setPaymentMethod={setPaymentSource}
              handleSubmitCheckout={handleSubmit}
            />
          </div>
          <div className="container-fluid">
            <CheckoutDisclaimer />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
