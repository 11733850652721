import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { formatMonto, S3_ENDPOINT } from "../../utils";
import moment from "moment";

const CheckoutClassPackage = ({ class_package_id }) => {
  const { descuento, class_package, getSingleClassPackage } =
    useContext(CheckoutContext);

  useEffect(() => {
    getSingleClassPackage(class_package_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrice = () => {
    return class_package.sale_price !== null && class_package.sale_price !== ""
      ? class_package.sale_price
      : class_package.price;
  };

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let total = class_package.price;
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = parseFloat(class_package.price) * porcentaje;
      } else {
        total = class_package.price - descuento.amount;
      }
      total = parseFloat(total).toFixed(2);
      return (
        <div className="container-fluid px-0">
          <h5>
            Total con Descuento: {"$"}
            {total} MXN
          </h5>
          <p>{renderBilling()}</p>
        </div>
      );
    }
  };

  const renderImage = () => {
    if (class_package.thumbnail !== null && class_package.image !== null) {
      return (
        <img
          alt="Suscripción My Legion"
          src={`${S3_ENDPOINT}/${class_package.image.name}.${class_package.image.type}`}
          className="class-package-thumbnail mb-3"
        />
      );
    }
  };

  const renderBilling = () => {
    const { is_subscription, subscription_period, subscription_interval } =
      class_package;
    let period = subscription_period;
    let interval = subscription_interval;
    if (is_subscription) {
      if (subscription_interval > 1) {
        period =
          subscription_period === "month"
            ? "meses"
            : subscription_period === "year"
            ? "años"
            : "días";
      } else {
        period =
          subscription_period === "month"
            ? "mes"
            : subscription_period === "year"
            ? "año"
            : "día";
      }
    }
    let frequency = "cada ";
    if (interval === 1) {
      interval = "";
    }
    if (interval || period !== null) {
      return `${frequency} ${interval} ${period}`;
    }
  };

  const renderFreeTrial = () => {
    if (class_package.free_trial_length !== null) {
      return `Tu primer cargo será el ${moment()
        .add(class_package.free_trial_length, "days")
        .format("DD MMM YYYY")}`;
    }
    if (class_package.free_trial_end !== null) {
      return `Tu primer cargo será el ${moment(class_package.free_trial_end)
        .utc()
        .format("DD MMM YYYY")}`;
    }
  };

  const renderClassPackage = () => {
    if (class_package && class_package !== null) {
      const price = getPrice();
      return (
        <div>
          {renderImage()}
          <h3>{class_package.title}</h3>
          <h4>{class_package.name}</h4>
          <p>{class_package.description}</p>
          <h5>
            {"$"}
            {formatMonto(price)}
            {" MXN "}
          </h5>
          <p>{renderBilling()}</p>
          <p className="mb-0 bold small">{renderFreeTrial()}</p>
          {renderResultadoDescuento()}
        </div>
      );
    }
  };

  return (
    <div className="mb-3">
      <h3>Estás Comprando</h3>
      <div className="mb-3 card bg-gray">{renderClassPackage()}</div>
    </div>
  );
};

export default CheckoutClassPackage;
