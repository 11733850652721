import React, { createContext, useContext, useReducer } from "react";
import MetodosReducer from "../reducers/MetodosReducer";
import MetodosService from "../services/MetodosService";
import { ModalContext } from "./ModalContext";
import { METODOS_RECIBIDOS } from "../types";
import { hideModal } from "../utils";

const initialState = {
  metodos: null,
  spinner: false,
};

export const MetodosContext = createContext(initialState);

export const MetodosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MetodosReducer, initialState);

  const { success } = useContext(ModalContext);

  const getMetodos = () => {
    MetodosService.getMetodos().then((res) => {
      const { payment_sources } = res.data;
      dispatch({ type: METODOS_RECIBIDOS, payload: payment_sources });
    });
  };

  const deleteMetodoPago = (payment_source_id) => {
    MetodosService.deleteMetodoPago(payment_source_id).then(() => {
      getMetodos();
      success("Metodo de Pago eliminado con exito.");
      hideModal();
    });
  };

  return (
    <MetodosContext.Provider
      value={{
        ...state,
        getMetodos,
        deleteMetodoPago,
      }}
    >
      {children}
    </MetodosContext.Provider>
  );
};
