import React from "react";
import { S3_ENDPOINT } from "../../utils";

const MilestoneImage = ({ milestone, className }) => {
  const renderImage = () => {
    const { file } = milestone;
    if (file && file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${milestone.file.name}.${milestone.file.type}`}
          className={className}
        />
      );
    }
    return <i className="fa fa-trophy mx-2" />;
  };
  return <div>{renderImage()}</div>;
};

export default MilestoneImage;
