import {
  INSTRUCTORES_RECIBIDAS,
  CREATE_INSTRUCTOR,
  SET_INSTRUCTOR,
  SET_PROPIEDAD_INSTRUCTOR,
} from "../types";

const schema = {
  instructor_id: "nueva",
  first_name: "",
  last_name: "",
  birthday: "",
  nick_name: "",
  idAdjunto: null,
};

const InstructorsReducer = (state, { type, payload }) => {
  switch (type) {
    case INSTRUCTORES_RECIBIDAS:
      return { ...state, instructors: payload, instructor: null };
    case SET_INSTRUCTOR:
      return { ...state, instructor: payload };
    case SET_PROPIEDAD_INSTRUCTOR:
      const instructor = { ...state.instructor };
      const { key, value } = payload;
      instructor[key] = value;
      return { ...state, instructor };
    case CREATE_INSTRUCTOR:
      return { ...state, instructor: schema };
    default:
      return { ...state };
  }
};

export default InstructorsReducer;
