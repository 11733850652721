import React from "react";
import amex from "../../assets/images/amex.png";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import { Link } from "@reach/router";

const Footer = () => {
  return (
    <div className="footer border-top bg-dark">
      <div className="container text-white py-5">
        <div className="row">
          <div className="col-12 col-md-4 my-3">
            <h4 className="bold">CONTACTO</h4>
            <a
              href="mailto: info@mylegion.mx"
              className="text-white no-decoration"
              rel="noreferrer"
              target="_blank"
            >
              <p>
                <i className="fa fa-envelope me-2"></i> info@mylegion.mx
              </p>
            </a>
            <a
              href="https://www.facebook.com/mylegionmx"
              className="text-white no-decoration"
              rel="noreferrer"
              target="_blank"
            >
              <p>
                <i className="fab fa-facebook me-2"></i> Legion
              </p>
            </a>
            <a
              href="https://www.instagram.com/mylegionmx"
              className="text-white no-decoration"
              rel="noreferrer"
              target="_blank"
            >
              <p>
                <i className="fab fa-instagram me-2"></i> @mylegionmx
              </p>
            </a>
          </div>
          <div className="col-12 col-md-4 my-3 pe-5">
            <h4 className="bold">UBICACIÓN</h4>
            <p>San Pedo Garza Garcia, N.L.</p>
            <h4 className="mt-4 bold">INFORMACIÓN</h4>
            <Link
              to="/terminos-y-condiciones"
              className="text-white no-decoration"
            >
              <p>Terminos y Condiciones</p>
            </Link>
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="bold">ACEPTAMOS</h4>
            <img
              src={amex}
              alt="American Express"
              className="payment-method me-2"
            />
            <img
              src={mastercard}
              alt="Mastercard"
              className="payment-method me-2"
            />
            <img src={visa} alt="Visa" className="payment-method me-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
