import React from "react";
import ClassTypeCard from "./ClassTypeCard";

const ClassTypeRow = ({ class_type }) => {
  return (
    <div className="row align-items-center mb-3">
      <div className="col-12 col-md-3">
        <ClassTypeCard class_type={class_type} />
      </div>
      <div className="col-12 col-md-9 position-relative">
        <div
          style={{
            top: 20,
            left: -100,
            width: 90,
            position: "absolute",
            borderBottom: "1px solid #fff",
          }}
          className="hide-mobile"
        ></div>
        <p>{class_type.description}</p>
      </div>
    </div>
  );
};

export default ClassTypeRow;
