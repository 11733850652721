import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import PaquetesReducer from "../reducers/PaquetesReducer";
import PaquetesService from "../services/PaquetesService";
import {
  SET_PAQUETE,
  CREATE_PAQUETE,
  PAQUETES_RECIBIDOS,
  SET_PROPIEDAD_PAQUETE,
  RESERVATIONS_RECIBIDAS,
} from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  paquetes: null,
  paquete: null,
};

export const PackagesContext = createContext(initialState);

export const PaquetesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PaquetesReducer, initialState);

  const { success } = useContext(ModalContext);

  const getPaquetes = () => {
    PaquetesService.getPaquetes().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: class_packages });
    });
  };

  const getPaquetesEspeciales = () => {
    PaquetesService.getPaquetesEspeciales().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: class_packages });
    });
  };

  const getAsistentesEspecial = (package_class_id) => {
    PaquetesService.getAsistentesEspecial(package_class_id).then((res) => {
      const { class_package, asistentes } = res.data;
      dispatch({ type: RESERVATIONS_RECIBIDAS, payload: asistentes });
      dispatch({ type: SET_PAQUETE, payload: class_package });
    });
  };

  const getPaquetesOnline = () => {
    PaquetesService.getOnline().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: class_packages });
    });
  };

  const getAllPaquetes = () => {
    PaquetesService.getAllPaquetes().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: class_packages });
    });
  };

  const getPaqueteAdmin = (package_class_id) => {
    PaquetesService.getPaqueteAdmin(package_class_id).then((res) => {
      const { class_package } = res.data;
      dispatch({ type: SET_PAQUETE, payload: class_package });
    });
  };

  const createPaquete = () => {
    dispatch({ type: CREATE_PAQUETE });
  };

  const setPropiedadPaquete = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_PAQUETE, payload: { key, value } });
  };

  const postPaquete = (paquete) => {
    const callback = () => {
      success("¡Paquete guardado con exito!");
      navigate("/mylegion/admin/paquetes");
    };
    if (isNaN(paquete.package_id)) {
      PaquetesService.postPaquete(paquete).then(callback);
    } else {
      PaquetesService.putPaquete(paquete).then(callback);
    }
  };
  const deletePaquete = (package_id) => {
    PaquetesService.deletePaquete(package_id).then(() => {
      success("¡Paquete eliminado con exito!");
      getAllPaquetes();
    });
  };

  return (
    <PackagesContext.Provider
      value={{
        ...state,
        getPaquetes,
        postPaquete,
        deletePaquete,
        createPaquete,
        getAllPaquetes,
        getPaqueteAdmin,
        getPaquetesOnline,
        setPropiedadPaquete,
        getAsistentesEspecial,
        getPaquetesEspeciales,
      }}
    >
      {children}
    </PackagesContext.Provider>
  );
};
