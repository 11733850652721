import { Link } from "@reach/router";
import React from "react";

const CheckoutHeader = () => {
  return (
    <div className="row border-bottom align-items-center pb-3 bg-dark">
      <div className="container-fluid">
        <div className="container pe-4">
          <div className="row align-items-center">
            <div className="col-8 col-md-10">
              <h1 className="text-white display-4 mb-0">Checkout</h1>
            </div>
            <div className="col-4 col-md-2 text-end">
              <Link to="/">
                <img
                  alt="My Legion Logo"
                  src="/images/icono-blanco.png"
                  className="logo-navbar"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
