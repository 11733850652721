import React, { useContext, useEffect } from "react";
import PanelHeader from "../components/panel/PanelHeader";
import InvoiceCard from "../components/invoices/InvoiceCard";
import { PurchasesContext } from "../context/PurchasesContext";

const Invoices = () => {
  const { invoices, getInvoices } = useContext(PurchasesContext);

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInvoices = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return (
          <tr>
            <td colSpan={5} className="py-2">
              No se te ha hecho ningun cargo.
            </td>
          </tr>
        );
      }
      return invoices.map((invoice) => (
        <InvoiceCard key={invoice.invoice_id} invoice={invoice} />
      ));
    }
  };

  return (
    <div className="container px-0">
      <PanelHeader title="Mis Compras" />
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="border bg-gray text-white bold mx-0 py-2">
            <tr>
              <td className="bold">Folio</td>
              <td className="bold">Compra</td>
              <td className="bold">Total</td>
              <td className="bold">Fecha</td>
              <td className="bold">Estado</td>
            </tr>
          </thead>
          <tbody>{renderInvoices()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoices;
