import React, { createContext, useContext, useReducer } from "react";
import ClassInstructorReducer from "../reducers/ClassInstructorReducer";
import ClassInstructorService from "../services/ClassInstructorService";
import {
  CREATE_CLASE,
  SEMANAS_RECIBIDAS,
  SET_CLASE,
  SET_PROPIEDAD_CLASE,
  CLASES_RECIBIDAS,
  TOTAL_RECIBIDO,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_MONTH,
  SET_WEEK,
  SET_START_DATE,
  SET_END_DATE,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  instructorClass: null,
  class_types: null,
  selectedWeek: 0,
  spinner: false,
  clases: null,
  clase: null,
  weeks: null,
};

export const ClassInstructorContext = createContext(initialState);

export const ClassInstructorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassInstructorReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const setMonth = (month) => {
    dispatch({ type: SET_MONTH, payload: month });
  };

  const setWeek = (week) => {
    dispatch({ type: SET_WEEK, payload: week });
  };

  const getClases = (page) => {
    dispatch({ type: SHOW_SPINNER });
    ClassInstructorService.getClases(page).then((res) => {
      const { clases, total } = res.data;
      dispatch({ type: CLASES_RECIBIDAS, payload: clases });
      dispatch({ type: TOTAL_RECIBIDO, payload: total });
      dispatch({ type: HIDE_SPINNER });
    });
  };

  const getAsistentes = (class_instructor_id) => {
    ClassInstructorService.getAsistentes(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const getSchedule = (start_date, end_date, filters) => {
    dispatch({ type: SHOW_SPINNER });
    ClassInstructorService.getWeeks(start_date, end_date, filters).then(
      (res) => {
        const { days } = res.data;
        dispatch({ type: SEMANAS_RECIBIDAS, payload: days });
        dispatch({ type: HIDE_SPINNER });
      }
    );
  };

  const getClase = (class_instructor_id) => {
    ClassInstructorService.getClase(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const createClase = () => {
    dispatch({ type: CREATE_CLASE });
  };

  const setClase = (clase) => {
    dispatch({ type: SET_CLASE, payload: clase });
  };

  const setPropiedadClase = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CLASE, payload: { key, value } });
  };

  const postClase = (clase) => {
    if (isNaN(clase.class_id)) {
      ClassInstructorService.postClase(clase).then((res) => {
        success("¡Clase guardada con exito!");
        hideModal();
      });
    } else {
      ClassInstructorService.putClase(clase).then((res) => {
        success("¡Clase guardada con exito!");
        hideModal();
      });
    }
  };

  const clearClase = () => {
    dispatch({ type: SET_CLASE, payload: null });
  };

  const eliminarClase = (clase) => {
    ClassInstructorService.deleteClase(clase).then(() => {
      success("¡Clase eliminada con exito!");
      hideModal();
    });
  };

  const postAsistenteClase = (
    customer_id,
    class_instructor_id,
    is_cash,
    is_paid
  ) => {
    ClassInstructorService.postAsistenteClase(
      customer_id,
      class_instructor_id,
      is_cash,
      is_paid
    )
      .then(() => {
        getAsistentes(class_instructor_id);
        success("¡Asistente agregado!");
        hideModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Lo sentimos, esta clase ya esta llena.");
          }
        }
      });
  };

  const postPayment = (class_reservation_id, is_paid, class_instructor_id) => {
    ClassInstructorService.postPayment(class_reservation_id, is_paid).then(
      () => {
        success(is_paid ? "Pago registrado." : "Pago cancelado.");
        getAsistentes(class_instructor_id);
      }
    );
  };

  const setStartDate = (start_date) => {
    dispatch({ type: SET_START_DATE, payload: start_date });
  };

  const setEndDate = (end_date) => {
    dispatch({ type: SET_END_DATE, payload: end_date });
  };

  return (
    <ClassInstructorContext.Provider
      value={{
        ...state,
        setClase,
        getClase,
        getClases,
        setMonth,
        setWeek,
        postClase,
        setEndDate,
        clearClase,
        postPayment,
        getSchedule,
        createClase,
        setStartDate,
        eliminarClase,
        getAsistentes,
        postAsistenteClase,
        setPropiedadClase,
      }}
    >
      {children}
    </ClassInstructorContext.Provider>
  );
};
