import React, { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { PurchasesContext } from "../../context/PurchasesContext";

const CancelForm = ({ purchase }) => {
  const [reason, setReason] = useState("");

  const { spinner, cancelSubscription } = useContext(PurchasesContext);

  const { alert } = useContext(ModalContext);

  const handleSubmit = () => {
    if (reason === "" || reason.length < 10) {
      return alert("Debes indicar un motivo de cancelacion.");
    }
    cancelSubscription(purchase.purchase_id, reason);
  };

  return (
    <div>
      <p>
        ¿Estas seguro que deseas cancelar tu suscripcion #{purchase.purchase_id}{" "}
        a {purchase.class_package.title}?
      </p>{" "}
      <p className="small">
        Si estas en periodo de prueba gratis perderas acceso a tus beneficios
        inmediatamente.
      </p>
      <p className="small">
        De lo contrario, perderas acceso a tus beneficios cuando termine tu
        ciclo actual de facturacion.{" "}
      </p>
      <p className="small">
        No se hara ningun reembolso por el ultimo cargo efectuado aun y cuando
        solo haya pasado un minuto de haberse realizado.
      </p>
      <p>Esta accion NO puede deshacerse,</p>
      <label>¿Cual es el motivo de tu cancelacion?</label>
      <input
        type="text"
        value={reason}
        className="form-control mb-3"
        onChange={(e) => setReason(e.target.value)}
      />
      <button
        disabled={spinner}
        onClick={handleSubmit}
        className="btn btn-danger btn-small"
      >
        {spinner ? (
          <div className="spinner-border"></div>
        ) : (
          "Entiendo, Cancelar Mi Membresia"
        )}
      </button>
    </div>
  );
};

export default CancelForm;
