import { ClassInstructorProvider } from "./context/ClassInstructorContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { ReservationsProvider } from "./context/ReservationsContext";
import { InstructorsProvider } from "./context/InstructorsContext";
import { MilestonesProvider } from "./context/MilestonesContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { LocationsProvider } from "./context/LocationsContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { PaquetesProvider } from "./context/PackageContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ModalProvider } from "./context/ModalContext";
import { UserProvider } from "./context/AuthContext";
import { HomeProvider } from "./context/HomeContext";
import { combineComponents } from "./context";
import Main from "./Main";
import "./index.css";
import { AppConfigProvider } from "./context/AppConfigContext";

const AppContextProvider = combineComponents([
  HomeProvider,
  MetodosProvider,
  CheckoutProvider,
  PurchaseProvider,
  PaquetesProvider,
  CustomerProvider,
  LocationsProvider,
  DiscountsProvider,
  ClassTypeProvider,
  MilestonesProvider,
  InstructorsProvider,
  ReservationsProvider,
  ClassCategoryProvider,
  ClassInstructorProvider,
]);

function App() {
  return (
    <AppConfigProvider>
      <ModalProvider>
        <UserProvider>
          <AppContextProvider>
            <Main />
          </AppContextProvider>
        </UserProvider>
      </ModalProvider>
    </AppConfigProvider>
  );
}

export default App;
