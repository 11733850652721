import React, { useContext, useState } from "react";
import { Link } from "@reach/router";
import MobileMenuItem from "./MobileMenuItem";
import { menuitems, cuenta } from "../../utils";
import MilestoneImage from "../milestones/MilestoneImage";
import { AuthContext } from "../../context/AuthContext";

const MobileMenu = ({ signOut }) => {
  const [display, setDisplay] = useState(false);
  const { user } = useContext(AuthContext);

  const renderMilestone = () => {
    if (user && user !== null) {
      const { milestone } = user;
      if (milestone && milestone !== null) {
        return <MilestoneImage milestone={milestone} className="logo-navbar" />;
      }
    }
  };

  return (
    <>
      <nav className="panel-mobile-menu navbar navbar-expand-lg navbar-dark fixed-top bg-dark py-2">
        <div className="container-fluid">
          <Link className="navbar-brand pb-0" to="/mylegion">
            <img
              src="/images/icono-blanco.png"
              alt="Icono"
              className="logo-navbar"
            />
          </Link>
          {renderMilestone()}
          <button
            className="navbar-toggler ms-auto"
            type="button"
            onClick={() => setDisplay(!display)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      <div
        className="bg-dark w-100 vh-100 drawer-menu"
        style={{
          position: "absolute",
          marginTop: 64,
          left: display ? 0 : "-100vw",
        }}
      >
        <ul className="navbar-nav pt-4 px-3">
          <h3 className="ps-2 text-white border-bottom pb-3">Menu</h3>
          {menuitems.map(({ name, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <h3 className="ps-2 mt-4 text-white border-bottom pb-3">Mi Cuenta</h3>
          {cuenta.map(({ name, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <li className="nav-item mobile mt-5">
            <button className="btn btn-link nav-link" onClick={signOut}>
              <i className="fa fa-sign-out-alt fa-flip-horizontal ps-2"></i>{" "}
              Salir
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileMenu;
