import React, { useContext, useEffect } from "react";
import PanelHeader from "../components/panel/PanelHeader";
import CancelForm from "../components/purchases/CancelForm";
import PurchaseCard from "../components/purchases/PurchaseCard";
import { ModalContext } from "../context/ModalContext";
import { PurchasesContext } from "../context/PurchasesContext";

const Purchases = () => {
  const { purchases, getPurchases, cancelSubscription } =
    useContext(PurchasesContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaucion",
      <CancelForm purchase={purchase} cancelSubscription={cancelSubscription} />
    );
  };

  const renderOrdenes = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return (
          <tr>
            <td colSpan={5} className="text-white py-2">
              No has hecho ninguna compra.
            </td>
          </tr>
        );
      }
      return purchases.map((purchase) => (
        <PurchaseCard
          key={purchase.purchase_id}
          purchase={purchase}
          cancelSubscription={confirmCancel}
        />
      ));
    }
  };

  return (
    <div className="container px-0">
      <PanelHeader title="Mis Compras" />
      <div className="table-responsive">
        <table className="table">
          <thead className="border bg-gray text-white bold mx-0 py-2">
            <tr>
              <td className="bold">Folio</td>
              <td className="bold">Paquete</td>
              <td className="bold">Total</td>
              <td className="bold">Fecha</td>
              <td className="bold">Duracion</td>
              <td className="bold">Estado</td>
              <td className="bold">Siguiente</td>
              <td className="bold">Expira</td>
              <td className="bold">Acciones</td>
            </tr>
          </thead>
          <tbody>{renderOrdenes()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Purchases;
