import { Link } from "@reach/router";
import React from "react";

const ExpiredCredits = () => {
  return (
    <div className="container-fluid px-0">
      <p>
        Lo sentimos. Has agotado todos tus creditos para reservar clases. Puedes
        comprar mas en Shop.
      </p>
      <p className="bold">
        Despues de comprar, debes regresar a reservar tu clase.
      </p>
      <Link to="/mylegion/shop" className="btn btn-primary">
        Ir a Shop
      </Link>
    </div>
  );
};

export default ExpiredCredits;
