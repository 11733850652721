import React, { useContext, useEffect } from "react";
import AccountDashboard from "../components/account/AccountDashboard";
import { AuthContext } from "../context/AuthContext";
import EditInformacion from "./EditInformacion";
import { navigate } from "@reach/router";
import { Router } from "@reach/router";
import MisMetodos from "./MisMetodos";
import Purchases from "./Purchases";
import Invoices from "./Invoices";

const Account = () => {
  const { user, getUsuario } = useContext(AuthContext);

  useEffect(() => {
    getUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user === null) {
      navigate("/entrar");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <Router>
        <AccountDashboard path="/" default />
        <MisMetodos path="/metodos-pago" />
        <EditInformacion path="/edit" />
        <Purchases path="/purchases" />
        <Invoices path="/invoices" />
      </Router>
    </div>
  );
};

export default Account;
