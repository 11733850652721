import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { cardStyle } from "../../utils";
import CheckoutService from "../../services/CheckoutService";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";

const StripeCreditCard = ({ element_id, discountCode }) => {
  //const [accept, setAccept] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { alert, success } = useContext(ModalContext);

  const { user } = useContext(AuthContext);

  const handleError = (message) => {
    setProcessing(false);
    alert(`Hubo un error al procesar tu compra: ${message}`);
  };

  const handleSuccess = (purchase_id) => {
    setProcessing(false);
    success("¡Compra exitosa!");
    navigate(`/gracias/${purchase_id}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });
    CheckoutService.attempt(element_id, discountCode, payment_method)
      .then(async (res) => {
        const { clientSecret, purchase_id } = res.data;
        if (clientSecret) {
          const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card,
            },
          });
          if (payload.error) {
            handleError(payload.error.message);
          } else {
            handleSuccess(purchase_id);
          }
        } else {
          handleSuccess(purchase_id);
        }
      })
      .catch((error) => {
        let message =
          "Lo sentimos. Hubo un error al procesar tu compra. Intenta de nuevo más tarde.";
        setProcessing(false);
        const { response } = error;
        if (response && response !== null) {
          const { data, status } = response;
          if (data && data !== null) {
            if (data.message && data.message !== null) {
              message = data.message;
            }
          } else if (status === 412) {
            message = "Lo sentimos. Este evento especial se ha llenado.";
          } else if (status === 409) {
            message =
              "Lo sentimos. Has alcanzado el límite de compras de este paquete.";
          } else if (status === 402) {
            message =
              "Lo sentimos. Tu tarjeta fue rechazada por decisión del banco o por fondos insuficientes.";
          }
        }
        alert(message);
      });
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <h5 className="border-bottom pb-2 mb-3">Tarjeta de Credito</h5>
        <CardElement
          id="card-element"
          options={cardStyle}
          className="form-control pt-2 my-2"
          onChange={handleChange}
        />
        {/*
        <div className="container-fluid px-0 py-2 mb-2">
          <label>
            <input
              type="checkbox"
              checked={accept}
              className="d-inline-block me-2"
              onChange={() => setAccept(!accept)}
            />
            <span>
              Acepto los{" "}
              <Link to="/terminos-y-condiciones">terminos y condiciones</Link>
            </span>
            .
          </label>
        </div>*/}
        <button
          className="btn btn-primary mt-2 bold"
          disabled={processing || disabled}
        >
          {processing ? <div className="spinner-border"></div> : "Pagar Ahora"}
        </button>
      </form>
    </div>
  );
};

export default StripeCreditCard;
