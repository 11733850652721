import React, { useContext, useEffect } from "react";
import { InstructorsContext } from "../context/InstructorsContext";
import Schedule from "../components/schedule/Schedule";
import Page from "../components/global/Page";
import { S3_ENDPOINT } from "../utils";
import parse from "html-react-parser";
import moment from "moment";

const SingleInstructorView = ({ instructor_id }) => {
  const { instructor, getInstructor } = useContext(InstructorsContext);

  useEffect(() => {
    getInstructor(instructor_id);
  }, [instructor_id]);

  const renderBio = () => {
    if (String(instructor.short_bio).startsWith("<")) {
      return parse(instructor.short_bio);
    }
    return instructor.short_bio;
  };

  const renderinstructor = () => {
    if (instructor && instructor !== null) {
      return (
        <div className="card p-3">
          <div className="row mx-0 align-items-center">
            <div className="col-4">
              {instructor.file !== null && instructor.file ? (
                <img
                  src={`${S3_ENDPOINT}/${instructor.file.name}.${instructor.file.type}`}
                  className="m-auto d-block mw-100 w-100"
                  style={{ borderRadius: "50%" }}
                  alt={instructor.name}
                />
              ) : (
                <div className="thumbnail round bg-accent user-icon">
                  <i className="fa fa-user fa-2x"></i>
                </div>
              )}
            </div>
            <div className="col-8">
              <h1 className="display-2">{instructor.nick_name}</h1>
              <p>
                {instructor.birthdate !== null &&
                  moment(instructor.birthdate).utc().format("DD MMM YYYY")}
              </p>
              <p>{renderBio()}</p>
              <h2>Tipo de Música</h2>
              <p>{instructor.music_type}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Page>
      <div className="pt-5">
        {renderinstructor()}
        <div className="card p-3 my-3">
          <h2 className="mb-4">
            Entrena con{" "}
            {instructor && instructor !== null ? instructor.nick_name : ""}
          </h2>
          <Schedule instructor_id={instructor_id} />
        </div>
      </div>
    </Page>
  );
};

export default SingleInstructorView;
