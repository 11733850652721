import { Link } from "@reach/router";
import { getAvailableClasses } from "../utils";
import { AuthContext } from "../context/AuthContext";
import React, { useContext, useEffect } from "react";
import Schedule from "../components/schedule/Schedule";
import { ClassTypeContext } from "../context/ClassTypesContext";
import { LocationsContext } from "../context/LocationsContext";
import { ReservationsContext } from "../context/ReservationsContext";
import ClassTypeRow from "../components/classTypes/ClassTypeRow";

const Presenciales = () => {
  const { user, getUsuario } = useContext(AuthContext);

  const { getMyReservations } = useContext(ReservationsContext);

  const { locations, getLocations } = useContext(LocationsContext);

  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getUsuario();
    getLocations();
    getClassTypes();
    getMyReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTipos = () => {
    if (class_types && class_types !== null) {
      return class_types.map((class_type) => (
        <ClassTypeRow key={class_type.class_type_id} class_type={class_type} />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-end border-bottom pb-3 mb-4">
        <div className="col-12 col-md-8">
          <h1>Calendario</h1>
        </div>
        <div className="col-12 col-md-4 text-right mobile-left">
          <h4 className="my-2">Disponibles: {getAvailableClasses(user)}</h4>
          <Link
            to="/mylegion/reservaciones"
            className="btn btn-outline-primary"
          >
            Ver Mis Reservaciones
          </Link>
        </div>
      </div>
      <Schedule locations={locations} />
      <h3 className="border-bottom pb-3 mt-5">Descubre los Tipos de Clases</h3>
      {renderTipos()}
    </div>
  );
};

export default Presenciales;
