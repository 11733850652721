import React, { useEffect, useState } from "react";
import moment from "moment";
import MapaLugares from "./MapaLugares";
import SingleInstructor from "../instructors/SingleInstructor";

const SingleClass = ({ clase, isGuest, postReservacion, hideModal }) => {
  const [place, setPlace] = useState(null);
  const [guest, setGuest] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (guest !== "" && error !== null) {
      setError(null);
    }
  }, [guest]);

  const handleSubmit = () => {
    if (isGuest) {
      if (guest === "") {
        return setError("Debes ingresar un nombre");
      }
    }
    if (place === null) {
      return setError("Debes elegir un lugar");
    }
    postReservacion({ ...clase, spot: place });
  };

  const renderInstructors = () => {
    if (Array.isArray(clase.class_instructors)) {
      return clase.class_instructors.map((class_instructor) => (
        <SingleInstructor
          key={class_instructor.instructor.instructor_id}
          instructor={class_instructor.instructor}
        />
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <h4 className="mb-1">
        <i className={clase.class_type.icon} /> {clase.class_type.name}
      </h4>
      <h3>{clase.description}</h3>
      <p className="large">
        <i className="far fa-calendar me-2"></i>{" "}
        {moment(clase.class_date).utc().format("DD MMM YYYY")}{" "}
        <i className="far fa-clock mx-2"></i>
        {moment(clase.class_date).utc().format("HH:mm")}
        hrs.
      </p>
      <h5 className="mt-4">Instructors</h5>
      {renderInstructors()}
      <h5>Ubicacion</h5>
      <p className="large">{clase.location.name}</p>
      <p>{clase.location.address}</p>
      {isGuest && (
        <div className="mb-3">
          <label className="bold">Nombre de Acompañante</label>
          <input
            type="text"
            value={guest}
            className="form-control"
            placeholder="Juan Perez"
            onChange={(e) => setGuest(e.target.value)}
          />
        </div>
      )}
      {clase.class_type !== null && clase.class_type.spot_map !== null && (
        <MapaLugares
          place={place}
          setPlace={setPlace}
          rows={clase.class_type.spot_map
            .split(",")
            .map((num) => parseInt(num))}
          icon={clase.icon}
          taken_spots={
            Array.isArray(clase.class_reservations)
              ? clase.class_reservations.map(({ spot }) => spot)
              : Array.isArray(clase.taken_spots)
              ? clase.taken_spots
              : []
          }
        />
      )}

      {error !== null && <p className="text-danger mb-1">{error}</p>}
      <div className="row mt-2">
        <div className="col col-md-6">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Reservar
          </button>
        </div>
        <div className="col col-md-6 text-right pe-0">
          <button className="btn btn-link text-muted" onClick={hideModal}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleClass;
