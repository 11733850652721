import React from "react";
import { contrast } from "../../utils";

const ClassTypeCard = ({ class_type }) => {
  const color = class_type.color !== null ? class_type.color : "#000";
  return (
    <div
      key={class_type.class_type_id}
      style={{ maxWidth: 300 }}
      className="p-2"
    >
      <div
        className="card text-white"
        style={{
          color: contrast(color),
          backgroundColor: color,
        }}
      >
        <h4 className="h5 border-bottom pb-3 mb-3">
          {class_type.name} <i className={class_type.icon}></i>
        </h4>
      </div>
    </div>
  );
};

export default ClassTypeCard;
