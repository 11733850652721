import React from "react";
import { Link } from "@reach/router";
import MilestoneImage from "../milestones/MilestoneImage";

const PanelNavbar = ({ user }) => {
  const renderMilestone = () => {
    if (user && user !== null) {
      const { milestone } = user;
      if (milestone && milestone !== null) {
        return <MilestoneImage milestone={milestone} className="logo-navbar" />;
      }
    }
  };

  return (
    <nav className="navbar navbar-expand-md bg-dark hide-mobile">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand py-2 ms-3">
          <img
            src="/images/icono-blanco.png"
            className="logo-navbar"
            alt="logo"
          />
        </Link>
        {renderMilestone()}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/mylegion/instructors" className="nav-link">
                Meet Your Coaches
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/mylegion" className="nav-link">
                Calendario
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/mylegion/reservaciones" className="nav-link">
                Reservaciones
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/mylegion/shop" className="nav-link">
                Paquetes
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/mylegion/account" className="nav-link">
                {user && user !== null ? user.name : ""}{" "}
                <i className="ms-2 fa fa-user-circle"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
