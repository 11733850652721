import React from "react";

const ScheduleTabs = ({ selected, setSelected }) => {
  return (
    <div className="btn-group w-100 my-3">
      <button
        onClick={() => setSelected(null)}
        className={`btn btn-${selected === "" ? "primary" : "outline-primary"}`}
      >
        TODO
      </button>
      <button
        onClick={() => setSelected(2)}
        className={`btn btn-${selected === 2 ? "primary" : "outline-primary"}`}
      >
        CYCLING
      </button>
      <button
        onClick={() => setSelected(3)}
        className={`btn btn-${selected === 3 ? "primary" : "outline-primary"}`}
      >
        FUNCTIONAL
      </button>
    </div>
  );
};

export default ScheduleTabs;
