import React, { useContext, useState, useEffect } from "react";
import { ReservationsContext } from "../../context/ReservationsContext";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import { getClassAvailableSpaces, getLocalMomentDiff } from "./utils";
import { getValue, hideModal } from "../../utils";
import { navigate } from "@reach/router";
import CancelClass from "../clases/CancelClass";
import SingleClass from "../clases/SingleClass";
import ExpiredCredits from "../global/ExpiredCredits";
import { RESERVATION_UNIT, RESERVATION_TRESHOLD } from "../../constants";
import moment from "moment";

const ScheduleClass = ({ singleClass, isHome }) => {
  const [loading, setLoading] = useState(false);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const { disabled, reservations, postReservacion, cancelReservacion } =
    useContext(ReservationsContext);

  useEffect(() => {
    if (!disabled && loading) {
      setLoading(false);
    }
  }, [disabled]);

  const handleReservation = (reservation) => {
    hideModal();
    clearModal();
    postReservacion(reservation);
    setLoading(true);
  };

  const handleSingleClass = () => {
    let notFull = getClassAvailableSpaces(singleClass) > 0;
    let hasCredits = user.available_classes.length > 0;
    let hasGuestCredits =
      user.available_classes.filter((available_class) =>
        getValue(available_class, "allow_guest", "boolean")
      ).length > 0;
    let diff = getLocalMomentDiff(singleClass.class_date, RESERVATION_UNIT);
    if (notFull && diff <= RESERVATION_TRESHOLD) {
      const isGuest = isReserved();
      if (hasCredits) {
        if ((isGuest && hasGuestCredits) || !isGuest) {
          return modalComponent(
            "Reservar Clase",
            <SingleClass
              clase={singleClass}
              hideModal={hideModal}
              isGuest={isReserved()}
              postReservacion={handleReservation}
            />,
            hideModal,
            () => postReservacion(singleClass)
          );
        } else if (isGuest) {
          return modalComponent(
            "Lo Sentimos",
            <div>
              <p>Tu paquete no permite reservar clases para invitados.</p>
              <button
                onClick={clearModal}
                className="btn mt-2 w-100 btn-primary"
              >
                OK!
              </button>
            </div>
          );
        }
      } else {
        modalComponent("Creditos Agotados", <ExpiredCredits />);
      }
    }
  };

  const showClassModal = () => {
    if (!disabled) {
      if (
        singleClass.class_package_id &&
        singleClass.class_package_id !== null
      ) {
        return navigate(`/checkout/${singleClass.class_package_id}`);
      }
      if (isHome) {
        return navigate("/studio/login");
      }
      if (isReserved()) {
        modalComponent(
          "Cancelar clase",
          <CancelClass
            singleClass={singleClass}
            reservations={reservations}
            cancelReservacion={cancelReservacion}
          />
        );
        //}
      } else {
        return handleSingleClass();
      }
    }
  };

  const isReserved = () => {
    if (Array.isArray(reservations)) {
      return getReservation() !== undefined;
    }
  };

  const getReservation = () => {
    if (Array.isArray(reservations)) {
      let hasReservation = reservations.find(
        ({ single_class_id, deletedAt }) =>
          parseInt(single_class_id) === parseInt(singleClass.single_class_id) &&
          deletedAt === null
      );

      return hasReservation;
    }
  };

  const renderIcon = () => {
    if (singleClass.class_type && singleClass.class_type !== null) {
      return <i className={singleClass.class_type.icon}></i>;
    }
  };

  const renderSpots = () => {
    if (loading) {
      return <div className="spinner-border text-white"></div>;
    }
    let reservation = getReservation();
    if (reservation) {
      return (
        <div className="container-fluid text-center px-1">
          <span className="text-primary">
            {renderIcon()} {reservation.spot}
          </span>
        </div>
      );
    }
    let diff = getLocalMomentDiff(singleClass.class_date, RESERVATION_TRESHOLD);
    if (diff <= RESERVATION_TRESHOLD) {
      const available_space = getClassAvailableSpaces(singleClass);
      if (available_space <= 0) {
        return <span className="badge badge-pill bg-danger">Sold Out</span>;
      }
      if (available_space <= 5) {
        return (
          <div>
            <span className="badge badge-pill bg-warning text-capitalize">
              {available_space} spots left
            </span>
          </div>
        );
      }
      return (
        <div>
          <span className="badge badge-pill bg-success txt-capitalize">
            Available
          </span>
        </div>
      );
    }
  };

  const renderInstructors = () => {
    return singleClass.class_instructors
      .map(
        ({ instructor }) =>
          `${
            instructor !== null
              ? instructor.nick_name !== null
                ? instructor.nick_name
                : instructor.name
              : ""
          }`
      )
      .join(", ");
  };

  const renderBeginners = () => {
    if ([3046, 3047].includes(singleClass.class_type_id)) {
      return (
        <span className="badge badge-pill bg-blue  text-capitalize">
          Beginners
        </span>
      );
    }
  };

  const renderLocation = () => {
    if (singleClass.location !== null) {
      return singleClass.location.name;
    }
  };

  const renderButton = () => {
    if (isReserved()) {
      return (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleSingleClass();
          }}
          className="btn btn-sm my-2 btn-primary"
        >
          <span className="me-1">+1</span> Spot
        </button>
      );
    }
  };

  return (
    <div
      className="schedule-class text-white p-2 pb-3 my-2 bg-gray border"
      onClick={showClassModal}
    >
      <p className="mb-1 bold">
        <i className={singleClass.class_type?.icon} />{" "}
        {singleClass.class_type?.name}
      </p>
      <p className="font-weight-bold mb-1">
        <i className="far fa-clock"></i>{" "}
        {moment(singleClass.class_date).utc().format("HH:mm")}
      </p>
      <p className="mb-0">{renderInstructors()}</p>
      <p className="mb-1 small bold">{renderLocation()}</p>
      {renderSpots()}
      {renderBeginners()}
      {renderButton()}
    </div>
  );
};

export default ScheduleClass;
