import React, { useContext, useEffect } from "react";
import moment from "moment";
import SingleClass from "../clases/SingleClass";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";
import { ReservationsContext } from "../../context/ReservationsContext";
import { ModalContext } from "../../context/ModalContext";
import CancelClass from "../clases/CancelClass";
import { ADMIN_URL, getStatusReservacionString, hideModal } from "../../utils";
import QRCode from "react-qr-code";
import { AppConfigContext } from "../../context/AppConfigContext";
import ClassReservationRating from "./ClassReservationRating";

const FilaReservaciones = ({ reservacion }) => {
  const { clase, getClase } = useContext(ClassInstructorContext);
  const { modalComponent } = useContext(ModalContext);
  const { reservations } = useContext(ReservationsContext);
  const { reservation_rating_enabled } = useContext(AppConfigContext);

  const { cancelReservacion, updateReservacion } =
    useContext(ReservationsContext);

  useEffect(() => {
    if (clase !== null) {
      if (clase.single_class_id === reservacion.single_class_id) {
        addPlace(clase);
      }
    }
  }, [clase]);

  const displayCode = () => {
    const { class_reservation_id } = reservacion;
    let urlString = `${ADMIN_URL}/myadmin/reservation/${class_reservation_id}`;
    modalComponent(
      "Código de Acceso",
      <QRCode style={{ width: "100%" }} value={urlString} />
    );
  };

  const addPlace = (clase) => {
    modalComponent(
      "Reservar Lugar",
      <SingleClass
        clase={clase}
        postReservacion={(clase) =>
          updateReservacion({
            ...clase,
            class_reservation_id: reservacion.class_reservation_id,
          })
        }
        hideModal={hideModal}
      />
    );
  };

  const confirmCancel = (reservation) => {
    modalComponent(
      "Cancelar Clase",
      <CancelClass
        reservations={reservations}
        singleClass={reservation.single_class}
        cancelReservacion={cancelReservacion}
      />
    );
  };

  const renderInstructors = (singleClass) => {
    if (singleClass.class_instructors.length === 1) {
      const instructor = singleClass.class_instructors[0].instructor;
      return instructor !== null
        ? instructor.nick_name !== null
          ? instructor.nick_name
          : instructor.name
        : "";
    }
    return singleClass.class_instructors.map(({ instructor }) => (
      <span className="d-block">
        {instructor !== null
          ? instructor.nick_name !== null
            ? instructor.nick_name
            : instructor.name
          : ""}
      </span>
    ));
  };

  const renderIcon = () => {
    const { single_class } = reservacion;
    if (single_class && single_class !== null) {
      const { class_type } = single_class;
      if (class_type && class_type !== null) {
        return <i className={class_type.icon}></i>;
      }
    }
  };

  const renderSpot = (reservation) => {
    if (reservation.spot !== null) {
      return (
        <span>
          <span className="show-mobile me-2">Spot</span>
          {reservation.spot}
        </span>
      );
    }
    if (reservacion.deletedAt === null) {
      return (
        <button
          className="btn btn-outline-primary"
          onClick={() => getClase(reservation.single_class_id)}
        >
          <i className="fas fa-map-pin"></i>
        </button>
      );
    }
  };

  const renderActions = () => {
    if (reservacion.deletedAt !== null) {
      return <span className="badge bg-danger">Cancelada</span>;
    }
    const diff =
      moment.utc(reservacion.single_class.class_date).diff(moment(), "hours") +
      5;
    if (diff > 3) {
      return (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => confirmCancel(reservacion)}
        >
          <i className="fa fa-times"></i>
        </button>
      );
    }
  };

  const handleRating = () => {
    modalComponent(
      "Calificar Clase",
      <ClassReservationRating
        class_reservation_id={reservacion.class_reservation_id}
      />
    );
  };

  const renderRating = () => {
    if (
        getStatusReservacionString(reservacion) === "Exitosa"
        && parseInt(reservation_rating_enabled)
        ) {
      if (reservacion.class_stars === null) {
        return (
          <button
            onClick={handleRating}
            className="btn btn-sm btn-outline-light"
          >
            <i className="fa fa-star me-1"></i> Calificar
          </button>
        );
      }
      return (
        <span>
          {reservacion.class_stars} <i className="fa fa-star text-warning"></i>
        </span>
      );
    }
  };

  return (
    <div
      key={reservacion.class_reservation_id}
      className="card reservation-card no-scale shadow-sm p-3"
    >
      <div className="row small align-items-center">
        <div className="col-12 col-md mb-2">
          <h5 className="mb-0">
            {renderIcon()} {reservacion.single_class.class_type.name}
          </h5>
        </div>
        <div className="col-12 col-md mb-2">
          {renderInstructors(reservacion.single_class)}
        </div>
        <div className="col-12 col-md mb-2">
          {moment(reservacion.single_class.class_date)
            .utc()
            .format("ddd DD MMM, HH:mm")}
        </div>
        <div className="col-12 col-md mb-2">{renderSpot(reservacion)}</div>
        <div className="col-12 col-md mb-2">
          <button className="btn btn-outline-light me-2" onClick={displayCode}>
            <i className="fa fa-qrcode"></i>
          </button>
          {renderActions()}
          {renderRating()}
        </div>
      </div>
    </div>
  );
};

export default FilaReservaciones;
