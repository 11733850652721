import React, { useState, useContext } from "react";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import FacebookLogin from "react-facebook-login";

const SignUpForm = () => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [signupReason, setSignUpReason] = useState("Recomendación");
  const [customReason, setCustomReason] = useState("");
  const [telefono, setTelefono] = useState("");
  const { spinner, signUp, facebookSignUp } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    signUp(
      nombre,
      email,
      password,
      telefono,
      signupReason === "otro" ? customReason : signupReason
    );
  };

  const renderOtro = () => {
    if (signupReason === "otro") {
      return (
        <div>
          <label>Cuéntanos más</label>
          <input
            type="text"
            className="form-control mb-3"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
          />
        </div>
      );
    }
  };
  return (
    <>
      <div id="login-card" className="card no-scale text-left shadow p-4">
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
          <label>Email</label>
          <input
            type="email"
            className="form-control mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Confirmar Password</label>
          <input
            type="password"
            className="form-control mb-3"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <label>WhatsApp</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
          />
          <label>¿Cómo te enteraste de nosotros?</label>
          <select
            className="form-control mb-3"
            value={signupReason}
            onChange={(e) => setSignUpReason(e.target.value)}
          >
            <option>Recomendación</option>
            <option>Anuncio en Instagram</option>
            <option>Anuncio en Facebook</option>
            <option>Vi el local</option>
            <option value="otro">Otro</option>
          </select>
          {renderOtro()}
          <button
            type="submit"
            className="btn btn-primary w-100 mb-3"
            value="Entrar"
          >
            {spinner ? <div className="spinner-border"></div> : "Registrate"}
          </button>
          <FacebookLogin
            appId="535175042039110"
            fields="name,email"
            callback={facebookSignUp}
            textButton="Regístrate con Facebook"
            icon={<i className="fab fa-facebook me-2"></i>}
            buttonStyle={{
              display: "block",
              width: "100%",
              padding: "8px",
            }}
          />
        </form>
        <div className="container-fluid px-0 mt-4">
          ¿Ya tienes cuenta?{" "}
          <Link to="/entrar" className="text-primary">
            Inicia Sesion
          </Link>
        </div>
      </div>
      <p className="mt-4 mw-500 d-block m-auto">
        <span className="text-white">¿Necesitas recuperar tu cuenta?</span>{" "}
        <Link to="/recuperar" className="text-primary">
          Haz click aqui
        </Link>
      </p>
    </>
  );
};

export default SignUpForm;
