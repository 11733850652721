import React, { createContext, useReducer, useContext } from "react";
import InstructorsReducer from "../reducers/InstructorsReducer";
import AdjuntosService from "../services/AdjuntosService";
import InstructorsService from "../services/InstructorsService";
import {
  INSTRUCTORES_RECIBIDAS,
  CREATE_INSTRUCTOR,
  SET_INSTRUCTOR,
  SET_PROPIEDAD_INSTRUCTOR,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  instructors: null,
  instructor: null,
};

export const InstructorsContext = createContext(initialState);

export const InstructorsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InstructorsReducer, initialState);

  const { success } = useContext(ModalContext);

  const getInstructors = () => {
    InstructorsService.getInstructors().then((res) => {
      const { instructors } = res.data;
      dispatch({ type: INSTRUCTORES_RECIBIDAS, payload: instructors });
    });
  };

  const getHomeInstructors = () => {
    InstructorsService.getHomeInstructors().then((res) => {
      const { instructors } = res.data;
      dispatch({ type: INSTRUCTORES_RECIBIDAS, payload: instructors });
    });
  };

  const getInstructor = (instructor_id) => {
    InstructorsService.getSingleInstructor(instructor_id).then((res) => {
      const { instructor } = res.data;
      dispatch({ type: SET_INSTRUCTOR, payload: instructor });
    });
  };

  const setInstructor = (instructor) => {
    dispatch({ type: SET_INSTRUCTOR, payload: instructor });
  };

  const createInstructor = () => {
    dispatch({ type: CREATE_INSTRUCTOR });
  };

  const setPropiedadInstructor = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_INSTRUCTOR, payload: { key, value } });
  };

  const postInstructor = (instructor) => {
    if (isNaN(instructor.instructor_id)) {
      if (instructor.file && instructor.file !== null) {
        const formData = new FormData();
        formData.append("adjunto", instructor.file);
        AdjuntosService.postAdjunto(formData).then((res) => {
          const { idAdjunto } = res.data;
          instructor.idAdjunto = idAdjunto;
          if (instructor.customer) {
            instructor.user_id = instructor.customer.customer_id;
          }
          InstructorsService.postInstructor(instructor).then(() => {
            getInstructors();
            hideModal();
            success("¡Instructor guardado!");
          });
        });
      } else {
        if (instructor.customer) {
          instructor.user_id = instructor.customer.customer_id;
        }
        InstructorsService.postInstructor(instructor).then(() => {
          getInstructors();
          hideModal();
          success("¡Instructor guardado!");
        });
      }
    } else {
      if (instructor.file && instructor.file !== null) {
        const formData = new FormData();
        formData.append("adjunto", instructor.file);
        AdjuntosService.postAdjunto(formData).then((res) => {
          const { idAdjunto } = res.data;
          instructor.idAdjunto = idAdjunto;
          if (instructor.customer) {
            instructor.user_id = instructor.customer.customer_id;
          }
          InstructorsService.putInstructor(instructor).then(() => {
            getInstructors();
            hideModal();
            success("¡Instructor guardado!");
          });
        });
      } else {
        if (instructor.customer) {
          instructor.user_id = instructor.customer.customer_id;
        }
        InstructorsService.putInstructor(instructor).then(() => {
          getInstructors();
          hideModal();
          success("¡Instructor guardado!");
        });
      }
    }
  };

  const deleteInstructor = (instructor_id) => {
    InstructorsService.deleteInstructor(instructor_id).then(() => {
      hideModal();
      getInstructors();
    });
  };

  return (
    <InstructorsContext.Provider
      value={{
        ...state,
        getInstructor,
        setInstructor,
        postInstructor,
        getInstructors,
        createInstructor,
        deleteInstructor,
        getHomeInstructors,
        setPropiedadInstructor,
      }}
    >
      {children}
    </InstructorsContext.Provider>
  );
};
