import React from "react";
import Page from "../../components/global/Page";

const Privacy = () => {
  return (
    <Page title="Aviso de Privacidad">
      <p>
        El presente documento constituye el “Aviso de Privacidad” de LEGION MX y
        de sus filiales y subsidiarias, en donde se establece la manera en que
        sera tratada su informacion por parte de LEGION MX, asi como la
        finalidad para la que fue recabada, lo anterior de conformidad con los
        articulos 15 y 16 de Ley Federal de Proteccion de Datos Personales en
        Posesion de los Particulares (la “Ley”).
      </p>
      <p>
        Tambien describe que tipo de datos son y que opciones tiene para limitar
        el uso de dichos datos, asi como el procedimiento para ejercer sus
        derechos, en cumplimiento a los principios de Licitud, Consentimiento,
        Calidad, Informacion, Finalidad, Lealtad, Proporcionalidad y
        Responsabilidad que la ley establece.
      </p>
      <p>
        LEGION MX respeta su derecho a la privacidad y proteccion de datos
        personales, datos personales sensibles, incluyendo sus datos financieros
        o patrimoniales, los cuales estan amparados bajo la Ley. La base de
        datos en donde consta su informacion es responsabilidad de LEGION MX, la
        cual se encuentra ubicada en el estado Nuevo Leon, asimismo su
        informacion se encuentra debidamente resguardada conforme a las
        disposiciones de seguridad administrativa, tecnica y fisica,
        establecidas en la Ley de la materia, para protegerla de los posibles
        daños, perdidas, alteracion o acceso no autorizado.
      </p>
      <p>
        El Titular de la Informacion reconoce y acepta que en caso de que este
        “Aviso de Privacidad” este disponible a traves de una pagina electronica
        (sitio web, pagina de Internet o similar) o algun otro dispositivo
        electronico, al hacer clic en “aceptar” o de cualquier otra forma seguir
        navegando en el sitio, o bien al proporcionar sus Datos a traves del
        mismo o a traves de cualquier medio electronico (Email, etc.),
        constituye una manifestacion de su consentimiento para que LEGION MX
        realice el tratamiento de sus Datos, de conformidad con este Aviso de
        Privacidad.
      </p>
      <h4>Datos Sensibles</h4>
      <p>
        El Titular de la Informacion reconoce y acepta, que debido a su relacion
        con LEGION MX no ha proporcionado, ni tendra que proporcionar “datos
        personales sensibles”, es decir, aquellos datos personales intimos o
        cuya realizacion debida o indebida pueda dar origen a discriminacion o
        conlleve un riesgo grave para este. En el supuesto de que el Titular de
        la Informacion proporcione datos del tipo de los llamados sensibles,
        debera estar de acuerdo en proporcionarlos previamente y dejara a LEGION
        MX libre de cualquier queja o reclamacion respectiva.
      </p>
      <h4>Solicitudes</h4>
      <p>
        El titular podra ejercer los derechos de acceso, rectificacion,
        cancelacion u oposicion, respecto a los datos personales que le
        conciernen, asi como tambien solicitar la revocacion de su
        consentimiento, enviando una solicitud a LEGION MX a traves del email
        info@mylegion.mx, indicando por lo menos su nombre y domicilio completo
        o cualquier otro dato o documento que permita su identificacion, asi
        como el objeto de su solicitud y/o tramite a efectuar. Lo anterior se
        debera realizar en base a la Ley y su Reglamento.
      </p>
      <h4>Finalidad</h4>
      <p>
        Sus datos personales e historial como cliente seran utilizados por
        LEGION MX o por cualquiera de sus subsidiaras y/o filiales, y quedaran
        registrados en nuestra base de datos, asi como cualquier otra
        informacion personal, sensible o de cualquier otra indole, siempre que
        se cumpla con lo establecido en la Ley y su Reglamento respecto a cada
        tipo de informacion. Esta informacion puede ser utilizada por LEGION MX
        para cualquiera de las siguientes finalidades:
      </p>
      <ul>
        <li>
          a. Finalidad Comercial
          <p>
            Nombre del Titular de la Informacion, domicilio, RFC, telefono,
            numero de cuenta de facturacion, fecha de nacimiento, datos
            bancarios.
          </p>
          <p>
            Esta informacion podra usarse de manera enunciativa mas no
            limitativa para: (1) ofrecerle productos y servicios, ya sea de
            manera fisica, telefonica, electronica o por cualquier otra
            tecnologia o medio que este al alcance de LEGION MX; (2) hacer de su
            conocimiento nuevos productos o servicios que puedan ser de interes
            en relacion con el servicio contratado; (3) realizar para su
            comodidad los cargos facturados de manera automatica; (4) emitir
            ordenes de compra o solicitudes de trabajo, segun corresponda; y/o
            (5) cualquier otra accion que sea necesaria para cumplir con los
            intereses de LEGION MX respecto al acuerdo que haya llegado con el
            Titular de la Informacion.
          </p>
        </li>
        <li>
          b. Finalidad Laboral
          <p>
            (i) En relacion con ofertas o solicitudes de trabajo mediante la
            presentacion de curriculum vitae, se podra pedir informacion como
            nombre, domicilio, telefono de contacto, Email, nivel de estudios,
            referencias personales, experiencia profesional, RFC, numero de
            seguridad social, CURP, identificacion oficial.
          </p>
          <p>
            Esta informacion podra usarse de manera enunciativa mas no
            limitativa para: (1) evaluacion como posible candidato para ocupar
            algun puesto vacante; (2) en su caso, la elaboracion del Contrato de
            Prestacion de Servicios y/o Contrato Individual de Trabajo
            correspondiente; (3) para proporcionar referencias en caso de que
            otra persona o empresa las solicite sobre candidatos.
          </p>
        </li>
        <li>
          c. Finalidad de Seguridad en el Acceso a las Instalaciones.
          <p>
            Esta informacion podra usarse de manera enunciativa mas no
            limitativa para: (1) respaldar, registrar y/o controlar el registro
            de las personas que accedan o visiten las instalaciones de LEGION MX
            ; (2) cumplir con los lineamientos de seguridad establecidos en las
            politicas internas de LEGION MX. En relacion con la informacion que
            se solicite para ingreso a las instalaciones de LEGION MX, se podra
            pedir informacion como nombre completo, firma, identificacion
            oficial, asi como informacion de equipo electronico que se vaya a
            ingresar a las instalaciones de LEGION MX.
          </p>
        </li>
      </ul>
      <h4>Transferencia</h4>
      <p>
        El Titular de la Informacion entiende y acepta que LEGION MX y/o
        cualquiera de sus subsidiarias y/o filiales, podra transferir sus datos
        personales a terceros que han sido contratados por LEGION MX para que
        realicen en su nombre y representacion ciertas tareas relacionadas con
        las actividades comerciales y de promocion de sus productos y/o
        servicios. Estas terceras partes pueden tratar los datos en cumplimiento
        de las instrucciones de LEGION MX, o tomar decisiones sobre ellos como
        parte de la prestacion de sus servicios. En cualquiera de los dos casos,
        LEGION MX seleccionara proveedores que considere confiables y que se
        comprometan, mediante un contrato u otros medios legales aplicables, a
        implementar las medidas de seguridad necesarias para garantizar un nivel
        de proteccion adecuado a sus datos personales. Derivado de lo anterior,
        LEGION MX exigira a sus proveedores que cumplan con medidas de seguridad
        que garanticen los mismos niveles de proteccion que LEGION MX implementa
        durante el tratamiento de sus datos como cliente de LEGION MX. Estas
        terceras partes seleccionadas tendran acceso a su informacion con la
        finalidad de realizar las tareas especificadas en el contrato de
        servicios aplicable que haya suscrito con LEGION MX. Si LEGION MX
        determina que un proveedor no esta cumpliendo con las obligaciones
        pactadas, tomara inmediatamente las acciones pertinentes.
      </p>
      <p>
        Si el titular, no acepta la transmision de sus datos personales de
        conformidad con lo estipulado en el parrafo anterior, puede ponerse en
        contacto con LEGION MX, por cualquiera de los medios establecidos en el
        presente Aviso de Privacidad.
      </p>
      <h4>Excepciones</h4>
      <p>
        Adicionalmente y de conformidad con lo estipulado en los Articulos 10,
        37 y demas relativos de la Ley y su Reglamento, LEGION MX quedara
        exceptuado de las obligaciones referentes al consentimiento para el
        Tratamiento y Transferencia de sus Datos, cuando:
      </p>
      <ul>
        <li>Este previsto en una Ley;</li>
        <li>Los datos figuren en fuentes de acceso publico;</li>
        <li>
          Los datos personales se sometan a un procedimiento previo de
          disociacion;
        </li>
        <li>
          Tenga el proposito de cumplir obligaciones derivadas de una relacion
          juridica entre el titular y el responsable;
        </li>
        <li>
          Exista una situacion de emergencia que potencialmente pueda dañar a un
          individuo en su persona o en sus bienes;
        </li>
        <li>
          Sean indispensables para la atencion medica, la prevencion,
          diagnostico, la prestacion de asistencia sanitaria, tratamientos
          medicos o la gestion de servicios sanitarios;
        </li>
        <li>Se dicte resolucion de autoridad competente;</li>
        <li>
          Cuando la transferencia sea precisa para el reconocimiento, ejercicio
          o defensa de un derecho en un proceso judicial, y
        </li>
        <li>
          Cuando la transferencia sea precisa para el mantenimiento o
          cumplimiento de una relacion juridica entre el responsable y el
          titular.
        </li>
      </ul>
      <h4>Modificaciones</h4>
      <p>
        En caso de que se requiera alguna modificacion a lo estipulado en el
        presente Aviso de Privacidad, LEGION MX se obliga a hacer del
        conocimiento los cambios que en su caso se requieran, por cualquier
        medio, incluidos los electronicos, previo aviso que se le de a usted
        para que se manifieste por su parte, lo que a su derecho convenga, ya
        que de no recibir negativa expresa y por escrito de su parte, o bien,
        respuesta alguna, se entendera que usted acepta de conformidad los
        cambios realizados.
      </p>
      <h4>Consentimiento del Titular</h4>
      <p>
        El Titular de la Informacion reconoce y acepta que en caso de que este
        “Aviso de Privacidad” este disponible a traves de una pagina electronica
        (sitio web, pagina de Internet o similar) o algun otro dispositivo
        electronico, al hacer clic en “aceptar” o de cualquier otra forma seguir
        navegando en el sitio, o bien al proporcionar sus Datos a traves del
        mismo o a traves de cualquier medio electronico (Email, etc.),
        constituye una manifestacion de su consentimiento para que LEGION MX
        realice el tratamiento de sus Datos, de conformidad con este Aviso de
        Privacidad.
      </p>
      <p>
        Asimismo, de igual manera manifiesta que en caso de que este “Aviso de
        Privacidad” este disponible por escrito, su firma, rubrica, nombre o
        huella o bien al proporcionar sus Datos, constituye una manifestacion de
        su consentimiento para que LEGION MX realice el tratamiento de sus
        datos, de conformidad con este “Aviso de Privacidad”.
      </p>
      <h4>Notificacion de cambios del aviso</h4>
      <p>
        Si LEGION MX cambia este aviso de privacidad, dichos cambios apareceran
        en el sitio web www.mylegion.mx
      </p>
      <p>
        Si usted tiene alguna pregunta o desea ejercer algun derecho relacionado
        con esta declaracion de privacidad, por favor comuniquese con LEGION MX
        y/o sus empresas filiales a la siguiente direccion: info@mylegion.mx
      </p>
      <p>
        Este aviso de privacidad ha sido creado en base a las reglas expedidas
        por la Ley Federal de Proteccion de Datos Personales en Posesion de los
        Particulares con fundamento en los articulos 16, 17 y 36 de la Ley y
        corresponsales de su Reglamento.
      </p>
    </Page>
  );
};
export default Privacy;
