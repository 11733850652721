import React from "react";
import { useContext } from "react";
import FacebookLogin from "react-facebook-login";
import { AuthContext } from "../../context/AuthContext";

const ChooseLogin = ({ setLogin }) => {
  const { facebookSignIn } = useContext(AuthContext);

  return (
    <div>
      <button
        className="btn btn-primary d-block w-100 mb-3"
        onClick={() => setLogin("email")}
      >
        <i className="fa fa-envelope"></i> Entrar con Email
      </button>
      <FacebookLogin
        appId="535175042039110"
        fields="name,email,picture"
        callback={facebookSignIn}
        textButton="Entrar con Facebook"
        icon={<i className="fab fa-facebook me-2"></i>}
        buttonStyle={{
          display: "block",
          width: "100%",
          padding: "8px",
        }}
      />
    </div>
  );
};

export default ChooseLogin;
