import React from "react";
import { formatMonto } from "../../utils";

const PaqueteCard = ({ paquete, action, buttonTitle, className }) => {
  const onSale = () => paquete.sale_price && paquete.sale_price !== null;

  const {
    title,
    price,
    sale_price,
    class_amount,
    short_description,
    is_special_event,
  } = paquete;
  return (
    <div className={"col-12 col-md-6 col-xl-4 my-3 position-relative"}>
      <div className="card package-card shadow-sm no-scale position-relative">
        <h3 className={`mb-0 m-auto d-block text-center ${is_special_event ? "text-warning" : ""}`} style={{ maxWidth: 225 }}>
            {title}
        </h3>
        <div className="border-bottom">
          <p className="pb-2 mb-0">{short_description}</p>
          <p className="pb-2 mb-0">Clases: {class_amount}</p>
        </div>
        {sale_price && sale_price !== null && (
          <div className="bg-danger text-white my-1">SALE</div>
        )}
        <div
          className="position-absolute"
          style={{ width: "90%", bottom: "1.2rem", left: "15px" }}
        >
          <p className="large mb-1">
            <b>Vigencia:</b> {paquete.expiration_days} dias
          </p>
          <h3 className="mb-2">
            {onSale() && (
              <span className="strike-through d-block mb-2 text-danger">
                {"$"}
                {formatMonto(price)}
              </span>
            )}
            {"$"}
            {formatMonto(onSale() ? sale_price : price)}
            {" MXN"}
          </h3>
          <button
            className={
              className ? className : "btn btn-primary bold btn-block w-100"
            }
            onClick={action}
          >
            {buttonTitle ? buttonTitle : "Comprar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaqueteCard;
